import { DocumentHelpModalStep } from 'interfaces/modal';

import ModalAjudaDocumentoDefault from './Default';
import ModalAjudaPortal from '../Portal';
import ModalAjudaHistoricoVendaOuServico from './Sale';
import ModalReadPermission from './ReadPermission';
import useStyles from '../style';

interface ModalAjudaDocumentoProps {
  currentStep: DocumentHelpModalStep;
  navigateToReadPermissionModal: () => void;
  navigateToSaleIssueModal: () => void;
  navigateToPortalIssueModal: () => void;
}

export default function ModalAjudaDocumento({
  currentStep,
  navigateToReadPermissionModal,
  navigateToSaleIssueModal,
  navigateToPortalIssueModal,
}: ModalAjudaDocumentoProps): JSX.Element {
  const classes = useStyles();

  const helpModals: Record<DocumentHelpModalStep, JSX.Element> = {
    default: (
      <ModalAjudaDocumentoDefault
        navigateToReadPermissionModal={navigateToReadPermissionModal}
        navigateToPortalIssueModal={navigateToPortalIssueModal}
        navigateToSaleIssueModal={navigateToSaleIssueModal}
      />
    ),
    'read-permission': <ModalReadPermission />,
    sale: <ModalAjudaHistoricoVendaOuServico />,
    portal: <ModalAjudaPortal />,
  };

  return <div className={classes.wrapper}>{helpModals[currentStep]}</div>;
}
