import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  text: {
    fontStyle: 'normal',
    color: theme.palette2.omie.surfaces.onSurface,
    letterSpacing: '0.5px',
    margin: '0px',

    '&.font400': {
      fontWeight: '400',
    },
    '&.font500': {
      fontWeight: '500',
    },
    '&.font600': {
      fontWeight: '600',
    },
    '&.font700': {
      fontWeight: '700',
    },

    '&.wrapanywhere': {
      overflowWrap: 'anywhere',
    },

    '&.surfaceVariant': {
      color: theme.palette2.omie.surfaces.onSurfaceVariant,
    },

    '&.openSans': {
      fontFamily: 'Open Sans',
    },

    '&.poppins': {
      fontFamily: 'Poppins',
    },

    '&.title': {
      '&.small': {
        fontSize: theme.fonts.title.fontSize.small,
        lineHeight: theme.fonts.title.lineHeight.small,
      },
      '&.medium': {
        fontSize: theme.fonts.title.fontSize.medium,
        lineHeight: theme.fonts.title.lineHeight.medium,
      },
      '&.large': {
        fontSize: theme.fonts.title.fontSize.large,
        lineHeight: theme.fonts.title.lineHeight.large,
      },
    },

    '&.label': {
      '&.small': {
        fontSize: theme.fonts.label.fontSize.small,
        lineHeight: theme.fonts.label.lineHeight.small,
      },
      '&.medium': {
        fontSize: theme.fonts.label.fontSize.medium,
        lineHeight: theme.fonts.label.lineHeight.medium,
      },
      '&.large': {
        fontSize: theme.fonts.label.fontSize.large,
        lineHeight: theme.fonts.label.lineHeight.large,
      },
    },

    '&.display': {
      '&.small': {
        fontSize: theme.fonts.display.fontSize.small,
        lineHeight: theme.fonts.display.lineHeight.small,
      },
      '&.medium': {
        fontSize: theme.fonts.display.fontSize.medium,
        lineHeight: theme.fonts.display.lineHeight.medium,
      },
      '&.large': {
        fontSize: theme.fonts.display.fontSize.large,
        lineHeight: theme.fonts.display.lineHeight.large,
      },
    },

    '&.headline': {
      '&.small': {
        fontSize: theme.fonts.headline.fontSize.small,
        lineHeight: theme.fonts.headline.lineHeight.small,
      },
      '&.medium': {
        fontSize: theme.fonts.headline.fontSize.medium,
        lineHeight: theme.fonts.headline.lineHeight.medium,
      },
      '&.large': {
        fontSize: theme.fonts.headline.fontSize.large,
        lineHeight: theme.fonts.headline.lineHeight.large,
      },
    },

    '&.body': {
      '&.small': {
        fontSize: theme.fonts.body.fontSize.small,
        lineHeight: theme.fonts.body.lineHeight.small,
      },
      '&.medium': {
        fontSize: theme.fonts.body.fontSize.medium,
        lineHeight: theme.fonts.body.lineHeight.medium,
      },
      '&.large': {
        fontSize: theme.fonts.body.fontSize.large,
        lineHeight: theme.fonts.body.lineHeight.large,
      },
    },

    '&.crm': {
      color: theme.palette2.crm.backgrounds.onBackground,
    },

    '&.vendas': {
      color: theme.palette2.vendas.backgrounds.onBackground,
    },

    '&.servicos': {
      color: theme.palette2.servicos.backgrounds.onBackground,
    },
   
    '&.compras': {
      color: theme.palette2.compras.backgrounds.onBackground,
    },

    '&.financas': {
      color: theme.palette2.financas.backgrounds.onBackground,
    },
    
    '&.contador': {
      color: theme.palette2.contador.backgrounds.onBackground,
    },

    '&.omie': {
      color: theme.palette2.omie.backgrounds.onBackground,
    },
  },
}));

export default useStyles;