import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  dialogRoot: {
    '&.MuiDialog-root': {
      top: 56,
      left: 56,
      right: 0,
      bottom: 'auto',
    },
    '&.MuiBackdrop-root .MuiDialogBackdrop': {
      backgroundColor: 'transparent !important',
    },
    '&.MuiPaper-root': {
      boxShadow: '0',
    },
  },
  wrapper: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
}));

export default useStyles;
