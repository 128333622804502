import Button from 'components/v2/Button';

import handsIcon from 'assets/images/left-menu/crm/hands.png';
import computerIcon from 'assets/images/left-menu/crm/computer.png';
import clockIcon from 'assets/images/left-menu/crm/clock.png';
import chartIcon from 'assets/images/left-menu/crm/chart.png';

import MenuCard from '../../MenuCard';
import CardGrid from '../../CardGrid';
import GridItem from '../../GridItem';
import CardTitle from '../../CardTitle';
import useStyles from './style';
import useAnalytics from 'hooks/useAnalytics';

const CrmItem = (): JSX.Element => {
  const classes = useStyles();

  const analytics = useAnalytics('menu', 'portal');

  const handleOmieClick = (): void => {
    analytics.click('crm_cta');
    window
      .open('https://www.omie.com.br/lp/plg/?utm_source=portal&utm_medium=plg&utm_campaign=item_menu', '_blank')
      ?.focus();
  };

  return (
    <div className={classes.wrapper}>
      <MenuCard>
        {/* eslint-disable-next-line max-len */}
        <CardTitle text="Acompanhe de perto seu negócio, com todas as informações sobre clientes, compras e vendas, atualizadas de forma automática no sistema." />

        <CardGrid>
          <GridItem
            icon={<img alt="Mãos dadas sinalizando fechamento de negócio" src={handsIcon} />}
            text="Visão simples das negociações"
          />
          <GridItem icon={<img alt="Computador" src={computerIcon} />} text="Delegue tarefas importantes" />
          <GridItem icon={<img alt="Relógio" src={clockIcon} />} text="Histórico de negociação ABC" />
          <GridItem icon={<img alt="Gráfico de linhas" src={chartIcon} />} text="Resumo do pipeline de vendas" />
        </CardGrid>

        <Button
          text="Conheça o sistema de gestão Omie"
          theme="crm"
          styled="tonal"
          fontSize="large"
          onClick={handleOmieClick}
        />
      </MenuCard>
    </div>
  );
};

export default CrmItem;
