/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  buttonMenu: {
    display: 'flex',
    padding: '0px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    width: '56px',
    height: '56px',
    minWidth: '100%',
    transition: 'filter 0.2s',

    '& > img': {
      filter: 'invert(41%) sepia(28%) saturate(227%) hue-rotate(189deg) brightness(95%) contrast(94%)',
    },
  },

  menucrm: {
    '&:hover, &.active': {
      background: `${theme.palette2.opacities.crm.opacity12}`,
      '& img': {
        filter: 'invert(13%) sepia(50%) saturate(6105%) hue-rotate(204deg) brightness(100%) contrast(84%)',
      },
    },
  },
  menuvendas: {
    '&:hover, &.active': {
      background: `${theme.palette2.opacities.vendas.opacity12}`,
      '& img': {
        filter: 'invert(34%) sepia(71%) saturate(553%) hue-rotate(353deg) brightness(99%) contrast(91%)',
      },
    },
  },
  menuservicos: {
    '&:hover, &.active': {
      background: `${theme.palette2.opacities.servicos.opacity12}`,
      '& img': {
        filter: 'invert(28%) sepia(92%) saturate(892%) hue-rotate(142deg) brightness(93%) contrast(101%)',
      },
    },
  },
  menucompras: {
    '&:hover, &.active': {
      background: `${theme.palette2.opacities.compras.opacity12}`,
      '& img': {
        filter: 'invert(25%) sepia(50%) saturate(1999%) hue-rotate(338deg) brightness(95%) contrast(87%)',
      },
    },
  },
  menufinancas: {
    '&:hover, &.active': {
      background: `${theme.palette2.opacities.financas.opacity12}`,
      '& img': {
        filter:
          'brightness(0) saturate(100%) invert(81%) sepia(27%) saturate(6814%) hue-rotate(41deg) brightness(92%) contrast(101%)',
      },
    },
  },
  menucontador: {
    '&:hover, &.active': {
      background: `${theme.palette2.opacities.contador.opacity12}`,
      '& img': {
        filter: 'invert(10%) sepia(91%) saturate(3428%) hue-rotate(179deg) brightness(103%) contrast(101%)',
      },
    },
  },
}));

export default useStyles;
