import React, { useContext } from 'react';
import { CompanyContext } from 'context/CompanyContext';
import useAnalytics from 'hooks/useAnalytics';
import { DocumentParcelaLink } from 'context/DocumentContext';
import { ReactComponent as IconeLink } from 'assets/images/icons/ic-link.svg';
import { ReactComponent as IconeDownload } from 'assets/images/icons/ic-download.svg';
import ButtonDefault from 'components/v2/Button';
import useStyles from './style/detalhe';

interface DetalheLinkProps {
  data: DocumentParcelaLink;
}

const DetalheLink = (props: DetalheLinkProps): JSX.Element => {
  const { data } = props;
  const company = useContext(CompanyContext);
  const analytics = useAnalytics('action_button_popup_parcela', 'portal');

  const classes = useStyles();

  const handleDownload = (): void => {
    analytics.download(data.id, { app_hash: company ? company.app_hash : null });
    window.open(data.url, '_blank')?.focus();
  };

  const handleOpen = (): void => {
    analytics.click(data.data.short_link, { app_hash: company ? company.app_hash : null });
    window.open(data.data.short_link, '_blank')?.focus();
  };

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        {data.url && (
          <ButtonDefault
            text="Baixar cobrança"
            theme="financas"
            styled="text"
            fontSize="large"
            Image={IconeDownload}
            onClick={handleDownload}
            imageSize="small"
          ></ButtonDefault>
        )}
        <ButtonDefault
          text="Acessar link"
          theme="financas"
          styled="tonal"
          fontSize="large"
          Image={IconeLink}
          onClick={handleOpen}
          imageSize="small"
        ></ButtonDefault>
      </div>
    </div>
  );
};

export default DetalheLink;
