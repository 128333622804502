import ListItem from 'components/v2/ListItem';

import ModalTitle from '../title';

interface ModalAjudaHistoricoDefaultProps {
  navigateToSaleIssueModal: () => void;
  navigateToPortalIssueModal: () => void;
}

export default function ModalAjudaHistoricoDefault({
  navigateToSaleIssueModal,
  navigateToPortalIssueModal,
}: ModalAjudaHistoricoDefaultProps): JSX.Element {
  return (
    <>
      <ModalTitle>Como podemos te ajudar?</ModalTitle>

      <ListItem
        title="Tenho um problema com uma venda ou serviço"
        subTitle="Problemas com valores, situação de parcelas e formas de pagamento"
        onClick={navigateToSaleIssueModal}
      />
      <ListItem
        title="Tenho um problema técnico com o portal"
        subTitle="Problemas para acessar, visualizar informações ou interagir com o portal Omie"
        showSeparator={false}
        onClick={navigateToPortalIssueModal}
      />
    </>
  );
}
