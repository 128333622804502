import React, { useState, useEffect, useContext } from 'react';
import { getQrCode } from 'resources';
import LoadingSpinner from 'components/LoadingSpinner';
import { CompanyContext } from 'context/CompanyContext';
import useAnalytics from 'hooks/useAnalytics';
import { DocumentParcelaPix } from 'context/DocumentContext';
import { PostQrCodeResponse } from 'interfaces/api';
import useStyles from './style/detalhe';
import ButtonDefault from 'components/v2/Button';
import { ReactComponent as IconeCopiar } from 'assets/images/icons/ic-copy.svg';
import { ReactComponent as IconeDownload } from 'assets/images/icons/ic-download.svg';
import { copyToClipboard } from 'utils';
import TextDefault from 'components/v2/Text';

interface DetalhePixProps {
  data: DocumentParcelaPix;
}

const DetalhePix = (props: DetalhePixProps): JSX.Element => {
  const { data } = props;
  const [qrCode, setQrCode] = useState<null | string>(null);
  const [isLoading, setLoading] = useState(true);
  const company = useContext(CompanyContext);
  const analytics = useAnalytics('action_button_popup_parcela', 'portal');

  const classes = useStyles();

  useEffect(() => {
    (async (): Promise<void> => {
      if (data.data.image) {
        setQrCode(data.data.image);
      } else {
        const qrCodeResponse = await getQrCode(data.data.qrcode);
        const { status } = qrCodeResponse;
        if (status === 'success') {
          const qrcode = (qrCodeResponse as PostQrCodeResponse).qrcode;
          setQrCode(qrcode);
        }
      }
      setLoading(false);
    })();
  }, [props, data.data]);

  const handleDownload = (): void => {
    analytics.download(data.id, { app_hash: company ? company.app_hash : null });
    window.open(data.url, '_blank')?.focus();
  };

  const handleCopy = (): void => {
    analytics.click('copiar código pix', { app_hash: company?.app_hash });
    copyToClipboard(data.data.qrcode, 'Pix copiado com sucesso.');
  };

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        {data.url && (
          <ButtonDefault
            text="Baixar cobrança"
            theme="financas"
            styled="text"
            fontSize="large"
            Image={IconeDownload}
            onClick={handleDownload}
            imageSize="small"
          ></ButtonDefault>
        )}
        <ButtonDefault
          text="Copiar chave Pix"
          theme="financas"
          styled="tonal"
          fontSize="large"
          Image={IconeCopiar}
          onClick={handleCopy}
          imageSize="small"
        ></ButtonDefault>
      </div>
      <div className={classes.details}>
        <div className={classes.doc}>
          <TextDefault text="Chave Pix" fontFamily="openSans" fontWeight="600" textType="label" />
          <TextDefault text={data.data.qrcode} fontFamily="openSans" textType="body" textWrap="anywhere" />
        </div>

        <div className={classes.image}>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <img src={`data:image/png;base64,${qrCode}`} alt={'QRCode'} width={'100%'} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DetalhePix;
