import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import LoadingSpinner from 'components/LoadingSpinner';
import { IEndereco } from 'context/CompanyContext';
import { stringifyAddress } from 'utils/address';
import useWindowSize from 'hooks/useWindowSize';
import { Logger, stage } from 'services';

const KEY = 'AIzaSyBb2WexEaDLreqU6zoXVeiUp5RvmaiFScQ';

export interface IPropsMaps {
  coordinates?: google.maps.LatLngLiteral
  address?: IEndereco,
  zoom: number,
  className?: string,
  sx?: React.CSSProperties,
  renderOnFail: JSX.Element,
}

function areEqual(prevProps: IPropsMaps, nextProps: IPropsMaps): boolean {
  if (prevProps.zoom !== nextProps.zoom) {
    return false;
  }

  if (prevProps.address !== nextProps.address) {
    return false;
  }

  const prevCoordinates = prevProps.coordinates;
  const nextCoordinates = nextProps.coordinates;

  if (prevCoordinates && nextCoordinates) {
    if (prevCoordinates.lat !== nextCoordinates.lat) {
      return false;
    }

    if (prevCoordinates.lng !== nextCoordinates.lng) {
      return false;
    }
  }

  if (prevProps.className !== nextProps.className) {
    return false;
  }

  return true;
}

function getPositionByBreakpoint(breakpoint: string): number {
  if (breakpoint === 'xl') return -300;
  if (breakpoint === 'lg') return -150;
  if (breakpoint === 'md') return -50;

  return 0;
}

const Map: React.FC<IPropsMaps> = React.memo((props) => {

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const { address, coordinates, zoom } = props;
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | google.maps.LatLng | null>(null);
  const { currentBreakpoint } = useWindowSize();

  const addressStringified = (address) ? stringifyAddress(address) : null;

  const panBy = useMemo(() => {
    return getPositionByBreakpoint(currentBreakpoint);
  }, [currentBreakpoint]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (ref.current && !map) {
        if (addressStringified) {
          try {
            const geocoder = new window.google.maps.Geocoder();
            const response = await geocoder.geocode({ address: addressStringified });
            if (response) {
              const { results } = response;
              if (results && results.length > 0) {
                const location = results[0].geometry.location;
                setMap(new window.google.maps.Map(ref.current, {
                  center: location,
                  zoom: zoom,
                  scaleControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                }));
                setMarkerPosition(location);
              }
            }          
          } catch (error) {
            Logger.error(error);
          }
        }

        if (coordinates && !addressStringified) {
          setMap(new window.google.maps.Map(ref.current, {
            center: coordinates,
            zoom: zoom,
            scaleControl: false,
            streetViewControl: false,
            mapTypeControl: false,
          }));
          setMarkerPosition(coordinates);
        }
      }

    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, map, coordinates, zoom, address]);

  if (map && markerPosition) {
    new google.maps.Marker({
      position: markerPosition,
      map: map,
    });
    if (stage !== 'prod') map.panBy(panBy, 0);
  }

  return (
    <div
      ref={ref}
      id="map"
      className={props.className}
      style={{ ...props.sx }}
    />
  );
}, areEqual);

const GoogleMaps = React.memo((props: IPropsMaps): JSX.Element => {

  if (!props.address && !props.coordinates) {
    return props.renderOnFail;
  }

  if (!props.address || !props.address.logradouro) {
    return props.renderOnFail;
  }

  const render = (status: Status): JSX.Element => {
    switch (status) {
      case Status.LOADING:
        return <LoadingSpinner />;
      case Status.FAILURE:
        return props.renderOnFail;
      case Status.SUCCESS:
        return <Map {...props} />;
    }
  };
  return (
    <Wrapper
      apiKey={KEY}
      render={render}
      language={'pt'}
    />
  );
}, areEqual);

export default GoogleMaps;