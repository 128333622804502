import React from 'react';
import { stageOmie } from 'services';
import dashboard from 'menu-items/dashboard';
import useAnalytics from 'hooks/useAnalytics';
import ButtonDefault from 'components/v2/Button';
import { ReactComponent as IconeLogin } from 'assets/images/icons/ic-login.svg';

export default function ButtonLogin(): JSX.Element {

  const analytics = useAnalytics('button_login_top', 'portal');

  const handleClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    analytics.click('click_login');
    
    let redirectTo = window.location.href;
    if (redirectTo.includes('/404')) {
      const urlStart = dashboard.children[0].url;
      redirectTo = redirectTo.replace('/404', urlStart);
    }
    
    window.location.href = `${stageOmie}/login/?redirect_to=${redirectTo}`;

  };
  
  return (
    <ButtonDefault text='Entrar' Image={IconeLogin} imagePosition='right' onClick={handleClick} />
  );
  
}
