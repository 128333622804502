import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';
import backgroundMap from 'assets/images/no-maps.png';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '32px',
  },
  actions: {
    width: '100%',
    display: 'flex',
    padding: '0px 16px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
  },
  doc: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  },
  image: {

  },
}));

export default useStyles;