import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  root: {
    width: '100%',
    background: theme.palette2.omie.surfaces.surfaceContainer1,
    position: 'fixed',
    right: 0,
    zIndex: 999,
    height: 'calc(100vh - 56px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '-webkit-animation': '$fadeInRight 0.5s',
    animation: '$fadeInRight 0.5s',
    '-webkit-animation-fill-mode': 'both',
    'animation-fill-mode': 'both',

    '&::-webkit-scrollbar': {
      width: '4px',
      height: '8px',
      backgroundColor: 'transparent',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette2.omie.outlines.outlineVariant,
      borderRadius: '50px',
    },

    '&.open': {
      display: 'block',
    },

    '@media (min-width: 1800px)': {
      maxWidth: '480px',
    },

    '@media (max-width: 1799px)': {
      maxWidth: '480px',
    },

    '@media (max-width: 1499px)': {
      maxWidth: '425px',
    },

    '@media (max-width: 1023px)': {
      width: '100%',
    },

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginRight: '8px',
    paddingTop: '16px',
  },

  '@-webkit-keyframes fadeInRight': {
    from: {
      opacity: 0,
      transform: 'translate3d(100%, 0, 0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
  },

  '@keyframes fadeInRight': {
    from: {
      opacity: 0,
      transform: 'translate3d(100%, 0, 0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
  },
}));

export default useStyles;
