import { HistoryHelpModalStep } from 'interfaces/modal';

import ModalAjudaHistoricoDefault from './Default';
import ModalAjudaHistoricoVendaOuServico from './Sale';
import ModalAjudaPortal from '../Portal';
import useStyles from '../style';

interface ModalAjudaHistoricoProps {
  currentStep: HistoryHelpModalStep;
  navigateToSaleIssueModal: () => void;
  navigateToPortalIssueModal: () => void;
}

export default function ModalAjudaHistorico({
  currentStep,
  navigateToSaleIssueModal,
  navigateToPortalIssueModal,
}: ModalAjudaHistoricoProps): JSX.Element {
  const classes = useStyles();

  const helpModals: Record<HistoryHelpModalStep, JSX.Element> = {
    default: (
      <ModalAjudaHistoricoDefault
        navigateToPortalIssueModal={navigateToPortalIssueModal}
        navigateToSaleIssueModal={navigateToSaleIssueModal}
      />
    ),
    sale: <ModalAjudaHistoricoVendaOuServico />,
    portal: <ModalAjudaPortal />,
  };

  return <div className={classes.wrapper}>{helpModals[currentStep]}</div>;
}
