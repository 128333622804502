import Text from 'components/v2/Text';
import { BaseTheme } from 'interfaces/base-layout';

import useStyles from './style';

interface MenuCardSmallProps {
  title: string;
  text: string;
  onClick?: () => void;
  theme?: BaseTheme;
}

export default function MenuCardSmall(props: MenuCardSmallProps): JSX.Element {
  const { title, text, onClick, theme = 'crm' } = props;

  const classes = useStyles();

  return (
    <button className={`${classes.card} ${theme}`} onClick={onClick}>
      <Text text={title} fontSize="large" textType="label" fontWeight="500" theme={theme} />
      <Text text={text} fontFamily="openSans" fontSize="small" textType="body" surfaceVariant />
    </button>
  );
}
