import omieVariables from 'assets/scss/_themes-vars-v2.module.scss';
import { PaletteOmie } from 'typings/v2/theme';

const theme = omieVariables;
 
const paletteDark: PaletteOmie = {
  omie: {
    brand: theme.brandBlue50,
    backgrounds: {
      background: theme.brandGray50,
      onBackground: theme.brandGray4,
    },
    errors: {
      error: theme.brandOrange70,
      onError: theme.neutrals0,
      errorContainer: theme.brandOrange10,
      onErrorContainer: theme.brandOrange90,
    },
    inverses: {
      inversePrimary: theme.inversePrimary,
      inverseSurface: theme.brandGray3,
      inverseOnSurface: theme.neutrals40,
    },
    primaries: {
      primary: theme.brandBlue30,
      onPrimary: theme.brandGray50,
      primaryContainer: theme.brandBlue30,
      onPrimaryContainer: theme.brandGray50,
    },
    secondaries: {
      secondary: theme.brandBlue20,
      onSecondary: theme.brandGray50,
      secondaryContainer: theme.brandBlue10,
      onSecondaryContainer: theme.brandGray50,
    },
    scrim: theme.brandGray50,
    shadow: theme.neutrals40,
    surfaces: {
      surface: theme.brandGray40,
      onSurface: theme.brandGray2,
      surfaceVariant: theme.brandGray50,
      onSurfaceVariant: theme.brandGray4,
      surfaceContainer1: theme.brandGray50,
      surfaceContainer2: theme.brandGray40,
      surfaceContainer3: theme.brandGray30,
      surfaceContainer4: theme.brandGray20,
      surfaceContainer5: theme.brandGray15,
      surfaceTint: theme.brandBlue30,
    },
    outlines: {
      outline: theme.neutrals20,
      outlineVariant: theme.neutrals30,
    },
    alerts: {
      alert: theme.alertDark,
      alertContainer: theme.alertContainer,
      onAlertContainer: theme.onAlertContainer,
    },
    notification: theme.brandOrange50,
    successes: {
      success: theme.success,
      successContainer: theme.successContainer,
      onSuccessContainer: theme.onSuccessContainer,
    },
    banners: {
      bannerWarning: theme.bannerWarning,
      bannerPositive: theme.bannerPositive,
      bannerNegative: theme.bannerNegative,
      bannerInfo: theme.bannerInfo,
      onBannerWarning: theme.onBannerWarning,
      onBannerPositive: theme.onBannerPositive,
      onBannerNegative: theme.onBannerNegative,
      onBannerInfo: theme.onBannerInfo,
    },
  },
  crm: {
    backgrounds: {
      background: theme.erpCRM1,
      onBackground: theme.erpCRM40,
    },
    primaries: {
      primary: theme.erpCRM50,
      primaryContainer: theme.erpCRM50,
      onPrimaryContainer: theme.whiteColor,
    },
    secondaries: {
      secondaryContainer: theme.erpCRM10,
      onSecondaryContainer: theme.erpCRM80,
    },
    outlines: {
      outline: theme.erpCRM20,
    },
  },
  vendas: {
    backgrounds: {
      background: theme.erpVendas1,
      onBackground: theme.erpVendas50,
    },
    primaries: {
      primary: theme.erpVendas30,
      primaryContainer: theme.erpVendas30,
      onPrimaryContainer: theme.whiteColor,
    },
    secondaries: {
      secondaryContainer: theme.erpVendas10,
      onSecondaryContainer: theme.erpVendas80,
    },
    outlines: {
      outline: theme.erpVendas20,
    },
  },
  servicos: {
    backgrounds: {
      background: theme.erpServicos1,
      onBackground: theme.erpServicos50,
    },
    primaries: {
      primary: theme.erpServicos30,
      primaryContainer: theme.erpServicos30,
      onPrimaryContainer: theme.whiteColor,
    },
    secondaries: {
      secondaryContainer: theme.erpServicos10,
      onSecondaryContainer: theme.erpServicos80,
    },
    outlines: {
      outline: theme.erpServicos20,
    },
  },
  compras: {
    backgrounds: {
      background: theme.erpCompras1,
      onBackground: theme.erpCompras50,
    },
    primaries: {
      primary: theme.erpCompras30,
      primaryContainer: theme.erpCompras30,
      onPrimaryContainer: theme.whiteColor,
    },
    secondaries: {
      secondaryContainer: theme.erpCompras10,
      onSecondaryContainer: theme.erpCompras80,
    },
    outlines: {
      outline: theme.erpCompras20,
    },
  },
  financas: {
    backgrounds: {
      background: theme.erpFinancas1,
      onBackground: theme.erpFinancas50,
    },
    primaries: {
      primary: theme.erpFinancas40,
      primaryContainer: theme.erpFinancas40,
      onPrimaryContainer: theme.whiteColor,
    },
    secondaries: {
      secondaryContainer: theme.erpFinancas10,
      onSecondaryContainer: theme.erpFinancas80,
    },
    outlines: {
      outline: theme.erpFinancas20,
    },
  },
  contador: {
    backgrounds: {
      background: theme.erpContador1,
      onBackground: theme.erpContador50,
    },
    primaries: {
      primary: theme.erpContador40,
      primaryContainer: theme.erpContador40,
      onPrimaryContainer: theme.whiteColor,
    },
    secondaries: {
      secondaryContainer: theme.erpContador10,
      onSecondaryContainer: theme.erpContador80,
    },
    outlines: {
      outline: theme.erpContador20,
    },
  },
  opacities: {
    omie: {
      primary: {
        opacity08: theme.primaryOpacity08Dark,
        opacity12: theme.primaryOpacity12Dark,
        opacity16: theme.primaryOpacity16Dark,
      },
      onPrimary: {
        opacity08: theme.onPrimaryOpacity08,
        opacity12: theme.onPrimaryOpacity12,
        opacity16: theme.onPrimaryOpacity16,
      },
      primaryContainer: {
        opacity08: theme.primaryContainerOpacity08,
        opacity12: theme.primaryContainerOpacity12,
        opacity16: theme.primaryContainerOpacity16,
      },
      onPrimaryContainer: {
        opacity08: theme.onPrimaryContainerOpacity08,
        opacity12: theme.onPrimaryContainerOpacity12,
        opacity16: theme.onPrimaryContainerOpacity16,
      },
      secondary: {
        opacity08: theme.secondaryOpacity08,
        opacity12: theme.secondaryOpacity12,
        opacity16: theme.secondaryOpacity16,
      },
      onSecondary: {
        opacity08: theme.onSecondaryOpacity08,
        opacity12: theme.onSecondaryOpacity12,
        opacity16: theme.onSecondaryOpacity16,
      },
      secondaryContainer: {
        opacity08: theme.secondaryContainerOpacity08,
        opacity12: theme.secondaryContainerOpacity12,
        opacity16: theme.secondaryContainerOpacity16,
      },
      onSecondaryContainer: {
        opacity08: theme.onSecondaryContainerOpacity08,
        opacity12: theme.onSecondaryContainerOpacity12,
        opacity16: theme.onSecondaryContainerOpacity16,
      },
      error: {
        opacity08: theme.errorOpacity08,
        opacity12: theme.errorOpacity12,
        opacity16: theme.errorOpacity16,
      },
      onError: {
        opacity08: theme.onErrorOpacity08,
        opacity12: theme.onErrorOpacity12,
        opacity16: theme.onErrorOpacity16,
      },
      errorContainer: {
        opacity08: theme.errorContainerOpacity08,
        opacity12: theme.errorContainerOpacity12,
        opacity16: theme.errorContainerOpacity16,
      },
      onErrorContainer: {
        opacity08: theme.onErrorContainerOpacity08,
        opacity12: theme.onErrorContainerOpacity12,
        opacity16: theme.onErrorContainerOpacity16,
      },
      outline: {
        opacity08: theme.outlineOpacity08,
        opacity12: theme.outlineOpacity12,
        opacity16: theme.outlineOpacity16,
      },
      background: {
        opacity08: theme.backgroundOpacity08,
        opacity12: theme.backgroundOpacity12,
        opacity16: theme.backgroundOpacity16,
      },
      onBackground: {
        opacity08: theme.onBackgroundOpacity08,
        opacity12: theme.onBackgroundOpacity12,
        opacity16: theme.onBackgroundOpacity16,
      },
      surface: {
        opacity08: theme.surfaceOpacity08Dark,
        opacity12: theme.surfaceOpacity12Dark,
        opacity16: theme.surfaceOpacity16Dark,
      },
      onSurface: {
        opacity08: theme.onSurfaceOpacity08,
        opacity12: theme.onSurfaceOpacity12,
        opacity16: theme.onSurfaceOpacity16,
      },
      surfaceVariant: {
        opacity08: theme.surfaceVariantOpacity08,
        opacity12: theme.surfaceVariantOpacity12,
        opacity16: theme.surfaceVariantOpacity16,
      },
      onSurfaceVariant: {
        opacity08: theme.onSurfaceVariantOpacity08,
        opacity12: theme.onSurfaceVariantOpacity12,
        opacity16: theme.onSurfaceVariantOpacity16,
      },
      inverseSurface: {
        opacity08: theme.inverseSurfaceOpacity08,
        opacity12: theme.inverseSurfaceOpacity12,
        opacity16: theme.inverseSurfaceOpacity16,
      },
      inverseOnSurface: {
        opacity08: theme.inverseOnSurfaceOpacity08,
        opacity12: theme.inverseOnSurfaceOpacity12,
        opacity16: theme.inverseOnSurfaceOpacity16,
      },
      inversePrimary: {
        opacity08: theme.inversePrimaryOpacity08,
        opacity12: theme.inversePrimaryOpacity12,
        opacity16: theme.inversePrimaryOpacity16,
      },
      shadow: {
        opacity08: theme.shadowOpacity08,
        opacity12: theme.shadowOpacity12,
        opacity16: theme.shadowOpacity16,
      },
      surfaceTint: {
        opacity08: theme.surfaceTintOpacity08,
        opacity12: theme.surfaceTintOpacity12,
        opacity16: theme.surfaceTintOpacity16,
      },
      outlineVariant: {
        opacity08: theme.outlineVariantOpacity08,
        opacity12: theme.outlineVariantOpacity12,
        opacity16: theme.outlineVariantOpacity16,
      },
      scrim: {
        opacity08: theme.scrimOpacity08,
        opacity12: theme.scrimOpacity12,
        opacity16: theme.scrimOpacity16,
      },
      surfaceContainerHighest: {
        opacity08: theme.surfaceContainerHighestOpacity08,
        opacity12: theme.surfaceContainerHighestOpacity12,
        opacity16: theme.surfaceContainerHighestOpacity16,
      },
      surfaceContainerHigh: {
        opacity08: theme.surfaceContainerHighOpacity08,
        opacity12: theme.surfaceContainerHighOpacity12,
        opacity16: theme.surfaceContainerHighOpacity16,
      },
      surfaceContainer: {
        opacity08: theme.surfaceContainerOpacity08,
        opacity12: theme.surfaceContainerOpacity12,
        opacity16: theme.surfaceContainerOpacity16,
      },
      surfaceContainerLow: {
        opacity08: theme.surfaceContainerLowOpacity08,
        opacity12: theme.surfaceContainerLowOpacity12,
        opacity16: theme.surfaceContainerLowOpacity16,
      },
      surfaceContainerLowest: {
        opacity08: theme.surfaceContainerLowestOpacity08,
        opacity12: theme.surfaceContainerLowestOpacity12,
        opacity16: theme.surfaceContainerLowestOpacity16,
      },
      surfaceBright: {
        opacity08: theme.surfaceBrightOpacity08,
        opacity12: theme.surfaceBrightOpacity12,
        opacity16: theme.surfaceBrightOpacity16,
      },
    },
    crm: {
      opacity08: theme.crmOpacity08Dark,
      opacity12: theme.crmOpacity12Dark,
      opacity16: theme.crmOpacity16Dark,
    },
    vendas: {
      opacity08: theme.vendasOpacity08,
      opacity12: theme.vendasOpacity12,
      opacity16: theme.vendasOpacity16,
    },
    servicos: {
      opacity08: theme.servicosOpacity08,
      opacity12: theme.servicosOpacity12,
      opacity16: theme.servicosOpacity16,
    },
    compras: {
      opacity08: theme.comprasOpacity08,
      opacity12: theme.comprasOpacity12,
      opacity16: theme.comprasOpacity16,
    },
    financas: {
      opacity08: theme.financasOpacity08,
      opacity12: theme.financasOpacity12,
      opacity16: theme.financasOpacity16,
    },
    contador: {
      opacity08: theme.contadorOpacity08,
      opacity12: theme.contadorOpacity12,
      opacity16: theme.contadorOpacity16,
    },
  },
  shadows: {
    boxShadow: theme.boxShadowDefault,
  },
};

export default paletteDark;