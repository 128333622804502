import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  menu: {
    '& .MuiPaper-root': {
      width: '270px !important',
      marginTop: '8px',
      borderRadius: '16px',
      background: theme.palette2.omie.surfaces.surfaceContainer1,
      boxShadow: theme.palette2.shadows.boxShadow,
    },

    '& .isLogout': {
      '& *': {
        color: theme.palette2.omie.notification,
      },
    },

    '& .MuiList-root': {
      padding: '8px 0px',
    },

    '& .MuiMenuItem-root': {
      padding: '8px 16px',
      height: '40px',
      gap: '8px',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },

    '& .MuiDivider-root': {
      margin: '0px',
      borderColor: theme.palette2.omie.surfaces.onSurfaceVariant,
    },
  },
  menuItem: {},
  itemIcon: {
    minWidth: '25px!important',
    textAlign: 'center',
  },
  itemIconBold: {
    color: theme.palette.secondary[800],
  },
  itemText: {
    margin: 0,
    padding: 0,
    lineHeight: 1,
  },
  itemTextBold: {
    fontWeight: '900 !important',
    color: theme.palette.primary[800],
  },
}));

export default useStyles;
