import Button from 'components/v2/Button';

import bookIcon from 'assets/images/left-menu/contador/book.png';
import calendarIcon from 'assets/images/left-menu/contador/calendar.png';
import integrationIcon from 'assets/images/left-menu/contador/integration.png';

import MenuCard from '../../MenuCard';
import CardGrid from '../../CardGrid';
import GridItem from '../../GridItem';
import CardTitle from '../../CardTitle';
import useStyles from './style';
import useAnalytics from 'hooks/useAnalytics';

const ContadorItem = (): JSX.Element => {
  const classes = useStyles();

  const analytics = useAnalytics('menu', 'portal');

  const handleOmieClick = (): void => {
    analytics.click('contador_cta');
    window
      .open('https://www.omie.com.br/lp/plg/?utm_source=portal&utm_medium=plg&utm_campaign=item_menu', '_blank')
      ?.focus();
  };

  return (
    <div className={classes.wrapper}>
      <MenuCard>
        {/* eslint-disable-next-line max-len */}
        <CardTitle text="Sua contabilidade recebe todas as informações necessárias sobre sua empresa em tempo real." />

        <CardGrid>
          <GridItem icon={<img alt="Calendário" src={calendarIcon} />} text="Lançamentos por período" />
          <GridItem icon={<img alt="Livro com cifrão na capa" src={bookIcon} />} text="Inclusão de guia de impostos" />
          <GridItem
            icon={<img alt="Integração entre sistemas" src={integrationIcon} />}
            text="Integração contábil e fiscal
"
          />
        </CardGrid>

        <Button
          text="Conheça o sistema de gestão Omie"
          theme="contador"
          styled="tonal"
          fontSize="large"
          onClick={handleOmieClick}
        />
      </MenuCard>
    </div>
  );
};

export default ContadorItem;
