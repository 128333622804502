import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  bannerContainer: {
    position: 'fixed',
    left: '0px',
    right: '0px',
    top: '0px',
    height: '48px',
    zIndex: '9999',
  },
}));

export default useStyles;