import { Button } from '@mui/material';
import useStyles from './style';
import { BaseFontSize, BaseStyleButton, BaseTheme } from 'interfaces/base-layout';
import { HTMLAttributeAnchorTarget } from 'react';

type StyleButtonColor = '' | 'background';
type DisplayButton = 'block' | '';
type ImagePosition = 'left' | 'right';
type ImageSize = 'auto' | 'small';

interface ButtonProps {
  text: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  Image?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  imagePosition?: ImagePosition;
  imageSize?: ImageSize;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  theme?: BaseTheme;
  styled?: BaseStyleButton;
  styledColor?: StyleButtonColor;
  fontSize?: BaseFontSize;
  display?: DisplayButton;
}

export default function ButtonDefault(props: ButtonProps): JSX.Element {
  const {
    text,
    onClick,
    Image,
    imagePosition = 'left',
    imageSize = 'auto',
    href,
    target = '_blank',
    theme = '',
    styled = 'text',
    styledColor = '',
    fontSize = 'medium',
    display = '',
  } = props;

  const classes = useStyles();

  return (
    <Button
      className={`${classes.button} ${theme} ${styled} ${styledColor} ${fontSize} ${display} font${imageSize}`}
      href={href ? href : ''}
      target={href ? target : ''}
      onClick={onClick}
    >
      {imagePosition === 'left' && Image && <Image />}
      <p className={classes.text}>{text}</p>
      {imagePosition === 'right' && Image && <Image />}
    </Button>
  );
}
