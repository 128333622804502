import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Avatar, Button, Chip, Fade, Paper, Popper } from '@mui/material';
import Transitions from 'ui-component/extended/Transitions';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuHeader from './MenuHeader/index';
import useStyles from 'components/v2/Button/style';
import { IUserAuth, IUserJwt, LocalStorageService } from 'services';
import useAuth from 'hooks/useAuth';
import { useLocation } from 'react-router-dom';

interface ButtonUserProps {
  user: IUserAuth;
}

export default function ButtonUser(props: ButtonUserProps): JSX.Element {
  const { user } = props;

  const classesButton = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorRef = React.useRef(null);
  const openMenu = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const auth = useAuth();
  const [userData, setUserData] = useState<IUserJwt | null>(auth.user);
  const location = useLocation();

  useEffect(() => {
    const userStorage = LocalStorageService.getUser();
    if (
      userStorage !== null &&
      userData !== null &&
      (userStorage.email !== userData.email || userStorage.first_name !== userData.first_name)
    ) {
      setUserData(LocalStorageService.getUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Button
        className={classesButton.button}
        ref={anchorRef}
        aria-controls={openMenu ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        href=""
      >
        <p className={classesButton.text}>{user.first_name}</p>

        <Avatar
          alt={(userData as IUserJwt).display_name}
          src={(userData as IUserJwt).avatar}
          sx={{
            border: 'none !important',
            width: '24px',
            height: '24px',
          }}
        ></Avatar>
      </Button>
      <Popper
        placement="bottom-end"
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }): JSX.Element => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <MenuHeader handleClose={handleClose} open={openMenu} anchorEl={anchorRef.current} />
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
}
