import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItemGroupItem } from 'interfaces/menu-items';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
// import useCredit from 'hooks/useCredit';
import ModalLogin from 'components/ModalLogin/index';
import { stageOmie } from 'services';
import useAnalytics from 'hooks/useAnalytics';
import useStyles from './style';
import ButtonDefault from 'components/v2/Button';

const STORAGE_ALERT_KEY = 'consulta_text_random';

interface MenuItemProps {
  item: MenuItemGroupItem;
  isActive: string | null;
  setActive: (url: string) => void;
}

function MenuItem(props: MenuItemProps): JSX.Element {
  const { item, setActive } = props;
  const navigate = useNavigate();
  // const credit = useCredit();
  const auth = useAuth();

  const analytics = useAnalytics('header_button', 'portal');

  const [needLogin, setNeedLogin] = useState(false);

  const [href, setHref] = useState<string | undefined>(undefined);

  const handleClick = (event: React.MouseEvent): void => {
    event.preventDefault();

    if (item.analytics === 'constraint_search') {
      if (window.location.href.indexOf(item.url) >= 0 && window.location.href.indexOf('planos') < 0) {
        return;
      }

      // obtem o texto atual no localstorage
      const storageKey = localStorage.getItem(STORAGE_ALERT_KEY);
      const text = storageKey !== null ? storageKey : '';

      if (text != '') {
        analytics.click(`header_button_${item.analytics}: ` + text);
      } else {
        analytics.click(`header_button_${item.analytics}`);
      }
    } else {
      analytics.click(`header_button_${item.analytics}`);
    }

    if (!auth.isAuthenticated && item.requiredLogin) {
      setHref(`${stageOmie}${item.url}`);
      setNeedLogin(true);
      return;
    } else {
      setHref(undefined);
    }

    if (item.external === true) {
      window.open(item.url, '_blank')?.focus();
    } else {
      navigate(item.url);
      setActive(item.url);
    }
  };

  const handleCloseModalLogin = (): void => {
    setNeedLogin(false);
  };

  return (
    <>
      {needLogin && (
        <ModalLogin
          href={href}
          open={true}
          message={
            'Visualize rapidamente seu histórico financeiro, notas fiscais emitidas, 2ª via de boletos e muito mais!'
          }
          handleClose={handleCloseModalLogin}
        />
      )}
      <Grid item xs={'auto'}>
          <ButtonDefault text={item.title} href={item.url} onClick={handleClick} />
      </Grid>
    </>
  );
}

export default function MenuRender(): JSX.Element | null {
  const [isActive, setActive] = useState<MenuItemGroupItem['url'] | null>(null);
  const auth = useAuth();
  const app = useApp();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      columnSpacing={{
        md: 1.95,
        lg: 5,
        xl: 6,
      }}
      className={classes.containerTop}
    >
      {app.menu.items.map((itemGroup) => {
        return itemGroup.children?.map((item: MenuItemGroupItem, index) => {
          if (!auth.isAuthenticated) {
            if (item.onlyAuthenticated !== false) return null;
          }

          if (item.onlyBeta === true) {
            if (auth.user?.app_ver !== 2) return null;
          }

          return <MenuItem key={item.url + '-' + index} item={item} isActive={isActive} setActive={setActive} />;
        });
      })}
    </Grid>
  );
}
