import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  title: {
    color: theme.palette2.omie.backgrounds.onBackground,
    fontFamily: 'Poppins',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '40px',
    marginTop: '0px',

    '&.h2': {
      color: theme.palette2.omie.surfaces.onSurface,
      fontSize: '22px',
      lineHeight: '28px',
    },

    '&.modalTitle': {
      color: theme.palette2.omie.surfaces.onSurface,
      fontSize: '28px',
      lineHeight: '36px',
    },
  },
}));

export default useStyles;