import { useNavigate } from 'react-router-dom';

import Button from 'components/v2/Button';

import chartIcon from 'assets/images/left-menu/vendas/chart.png';
import magnifierIcon from 'assets/images/left-menu/vendas/magnifier.png';
import contractIcon from 'assets/images/left-menu/vendas/contract.png';
import filterCoinIcon from 'assets/images/left-menu/vendas/filter-coin.png';

import CardGrid from '../../CardGrid';
import CardTitle from '../../CardTitle';
import GridItem from '../../GridItem';
import MenuCard from '../../MenuCard';
import MenuCardSmall from '../../MenuCardSmall';
import useStyles from './style';
import useAnalytics from 'hooks/useAnalytics';

interface VendasItemProps {
  closeMenu: () => void;
}

const VendasItem = ({ closeMenu }: VendasItemProps): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const analytics = useAnalytics('menu', 'portal');

  const handleSearchCnpjCpf = (): void => {
    analytics.click('vendas_card1');
    navigate('/consulta-cnpj-cpf');
    closeMenu();
  };

  const handleOmieClick = (): void => {
    analytics.click('vendas_cta');
    window
      .open('https://www.omie.com.br/lp/plg/?utm_source=portal&utm_medium=plg&utm_campaign=item_menu', '_blank')
      ?.focus();
  };

  return (
    <div className={classes.wrapper}>
      <MenuCardSmall
        onClick={handleSearchCnpjCpf}
        text="Aumente a segurança das suas vendas"
        title="Consulta de restrição de CPF/CNPJ"
        theme="vendas"
      />

      <div className={classes.cardWrapper}>
        <MenuCard>
          {/* eslint-disable-next-line max-len */}
          <CardTitle text="Gerencie e fature vendas de produtos com um sistema completamente integrado e automatizado." />

          <CardGrid>
            <GridItem icon={<img alt="Gráfico de barras" src={chartIcon} />} text="Análise de estratégias de venda" />
            <GridItem icon={<img alt="Contrato em papel" src={magnifierIcon} />} text="Consulta de crédito" />
            <GridItem icon={<img alt="Relógio" src={contractIcon} />} text="Emissão de notas fiscais" />
            <GridItem
              icon={<img alt="Moeda entrando pela parte superior de um filtro" src={filterCoinIcon} />}
              text="Gestão de vendas em pipeline"
            />
          </CardGrid>

          <Button
            text="Conheça o sistema de gestão Omie"
            theme="vendas"
            styled="tonal"
            fontSize="large"
            onClick={handleOmieClick}
          />
        </MenuCard>
      </div>
    </div>
  );
};

export default VendasItem;
