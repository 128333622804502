import { Grid, IconButton } from '@mui/material';
import ProfileSection from './ProfileSection';
import MenuIcon from '@mui/icons-material/Menu';
import useWindowSize from 'hooks/useWindowSize';
import HeaderLogo from './Logo';
import Cart from '../../Header/Cart';
import useStyles from './style';
import MenuRender from './Menu';

interface HeaderProps {
  handleLeftDrawerToggle: () => void;
  isBannerVisible: boolean;
}

const Header = (props: HeaderProps): JSX.Element => {
  const windowSize = useWindowSize();
  const { handleLeftDrawerToggle, isBannerVisible } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.header}
      sx={{
        marginTop: isBannerVisible ? '48px!important' : '0px',
      }}
    >
      {windowSize.isDown('md') ? (
        <>
          <Grid item xs={'auto'}>
            <IconButton
              size={'large'}
              edge={'start'}
              color={'primary'}
              onClick={handleLeftDrawerToggle}
              sx={{
                fontSize: '2.5rem',
              }}
            >
              <MenuIcon fontSize={'inherit'} />
            </IconButton>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={'auto'}>
            <HeaderLogo />
          </Grid>
          <Grid item xs={'auto'}></Grid>
        </>
      )}
      <Grid item xs></Grid>
      {/* <Cart /> */}
     
      <Grid item xs={'auto'} mr={2}>
        <MenuRender />
      </Grid>
      <Grid item xs={'auto'}>
        
        <ProfileSection />
      </Grid>
    </Grid>
  );
};

export default Header;
