import ModalTitle from '../title';
import ModalParagraph from '../paragraph';

export default function ModalReadPermission(): JSX.Element {
  return (
    <>
      <ModalTitle>Quem pode ver este documento?</ModalTitle>

      <ModalParagraph>
        O link para este documento fica disponível por 31 dias após o envio, e qualquer pessoa que tenha o link pode
        acessar.
      </ModalParagraph>

      <ModalParagraph>
        Depois desse período, o link fica disponível apenas para pessoas cadastradas no Portal Omie.
      </ModalParagraph>
    </>
  );
}
