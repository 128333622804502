import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  wrapper: {
    padding: '16px 8px',
  },
  emailLink: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: theme.palette2.omie.surfaces.onSurface,
    fontFamily: 'Open Sans',
    padding: '0 16px',
  },
}));

export default useStyles;
