import Text from 'components/v2/Text';
import useStyles from './style';

interface ModalParagraphProps {
  children: string;
}

export default function ModalParagraph({ children }: ModalParagraphProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Text text={children} textType="body" fontSize="large" fontFamily='openSans' />
    </div>
  );
}
