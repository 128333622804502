import { Logger } from 'services';
import xmlserializer from 'xmlserializer';

/**
 * Representation of Start portion of the barcode
 *
 * @default
 * @constant
 */
const START = '1111';

/**
 * Representation of Stop portion of the barcode
 *
 * @default
 * @constant
 */
const STOP = '211';

/**
 * Representations of each decimal digit
 *
 * @default
 * @constant
 */
const WEIGHTS = [
  '11221', // 0
  '21112', // 1
  '12112', // 2
  '22111', // 3
  '11212', // 4
  '21211', // 5
  '12211', // 6
  '11122', // 7
  '21121', // 8
  '12121', // 9
];

/**
 * Converts a pair of digits into their ITF representation and interleave them
 *
 * @param {String} pair The pair to be interleaved
 * @return {String} The input pair encoded into its ITF representation
 *
 * @example
 * // Returns "1211212112"
 * ITF.interleavePair('01');
 */
function interleavePair(pair) {
  const black = WEIGHTS[Math.floor(pair / 10)];
  const white = WEIGHTS[pair % 10];

  let p = '';

  for (let i = 0; i < 5; i += 1) {
    p += black[i];
    p += white[i];
  }

  return p;
}

function encode(number) {
  return START + number.match(/(..?)/g).map(interleavePair).join('') + STOP;
}

class SVG {
  /**
   * Initializes the class
   *
   * @constructor
   * @param {Array} stripes The list of stripes to be drawn
   * @param {Integer} stripeWidth The width of a single-weighted stripe
   */
  constructor(stripes, stripeWidth, height) {
    this.stripes = stripes.split('').map((a) => parseInt(a, 10));
    this.stripeWidth = stripeWidth || 4;
    this.height = height;
  }

  /**
   * Appends an SVG object and renders the barcode inside it
   *
   * The structure of the SVG is a series of parallel rectangular stripes whose
   * colors alternate between black or white.
   * These stripes are placed from left to right. Their width will vary
   * depending on their weight, which can be either 1 or 2.
   *
   * @param {String} selector The selector to the object where the SVG must be
   * appended
   */
  render(selector, height) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    let pos = 0;
    let width = 0;

    for (let i = 0; i < this.stripes.length; i += 1, pos += width) {
      width = this.stripeWidth * this.stripes[i];

      const shape = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      shape.setAttribute('width', width);
      shape.setAttribute('height', height * 2);
      shape.setAttribute('fill', SVG.color(i));
      shape.setAttribute('x', pos);
      shape.setAttribute('y', 0);
      svg.appendChild(shape);
    }

    svg.setAttribute('width', '100%');
    svg.setAttribute('height', '100%');
    svg.setAttribute('viewBox', `0 0 ${this.viewBoxWidth()} 100`);

    if (selector === undefined) {
      return xmlserializer.serializeToString(svg);
    }

    document.querySelector(selector).appendChild(svg);
    return null;
  }

  /**
   * Calculates the total width of the barcode
   *
   * The calculation method is the sum of the weight of the stripes multiplied
   * by the width of a single-wighted stripe
   *
   * @return {Integer} The width of a view box that fits the barcode
   */
  viewBoxWidth() {
    return this.stripes.reduce((a, b) => a + b, 0) * this.stripeWidth;
  }

  /**
   * Returns the appropriate color for each stripe
   *
   * Odd numbers will return white, even will return black
   *
   * @param {Integer} i The index of the stripe
   * @return {String} The stripe color
   *
   * @example
   * // Returns "#ffffff"
   * svg.color(1);
   * // Returns "#000000"
   * svg.color(2);
   */
  static color(i) {
    return i % 2 ? '#ffffff' : '#000000';
  }
}

class Boleto {
  /**
   * Initializes the class
   *
   * @constructor
   * @param {String} bankSlipNumber The bank slip number
   */
  constructor(bankSlipNumber, height, validate = true) {
    this.bankSlipNumber = bankSlipNumber.replace(/[^\d]/g, '');
    this.height = height;

    if (validate && !this.valid()) {
      throw new Error('Invalid bank slip number');
    }
  }

  /**
   * Calculates the modulo 11 checksum digit
   *
   * The specifications of the algorithm can be found at
   * https://portal.febraban.org.br/pagina/3166/33/pt-br/layour-arrecadacao
   *
   * @params {Array|String} number
   * @return {Integer} The modulo 11 checksum digit
   *
   * @example
   * // Returns 7
   * modulo11('123456789');
   */
  modulo11(number) {
    let digits = number;

    if (typeof digits === 'string') {
      digits = digits.split('');
    }

    digits.reverse();

    let sum = 0;

    for (let i = 0; i < digits.length; i += 1) {
      sum += ((i % 8) + 2) * digits[i];
    }

    return (11 - (sum % 11)) % 10 || 1;
  }

  /**
   * Validates whether the bank slip number is valid or not
   *
   * The validation function ensures that the bank slip number is exactly 47
   * characters long, then applies the modulo-11 algorithm to the bank slip's
   * barcode. Finally, it verifies that the result of the algorithm equals the
   * checksum digit from the bank slip number.
   *
   * @return {Boolean} Whether the bank slip number is valid or not
   */
  valid() {
    if (this.bankSlipNumber.length !== 47) {
      Logger.error('bankSlipNumber.length !== 47');
      return false;
    }

    const barcodeDigits = this.barcode().split('');
    const checksum = barcodeDigits.splice(4, 1);
    const responseModulo11 = this.modulo11(barcodeDigits).toString();

    return (responseModulo11 === checksum.toString());
  }

  /**
   * Converts the printed bank slip number into the barcode number
   *
   * The bank slip's number is a rearrangement of its barcode, plus three
   * checksum digits. This function executes the inverse process and returns the
   * original arrangement of the code. Specifications can be found at
   * https://portal.febraban.org.br/pagina/3166/33/pt-br/layour-arrecadacao
   *
   * @return {String} The barcode extracted from the bank slip number
   */
  barcode() {
    return this.bankSlipNumber.replace(
      /^(\d{4})(\d{5})\d{1}(\d{10})\d{1}(\d{10})\d{1}(\d{15})$/,
      '$1$5$2$3$4',
    );
  }

  /**
   * Returns the bank slip's raw number
   *
   * @return {String} The raw bank slip number
   */
  number() {
    return this.bankSlipNumber;
  }

  /**
   * Returns the bank slip number with the usual, easy-to-read mask:
   * 00000.00000 00000.000000 00000.000000 0 00000000000000
   *
   * @return {String} The formatted bank slip number
   */
  prettyNumber() {
    return this.bankSlipNumber.replace(
      /^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})$/,
      '$1.$2 $3.$4 $5.$6 $7 $8',
    );
  }

  /**
   * Returns the name of the bank that issued the bank slip
   *
   * This function is able to identify the most popular or commonly used banks
   * in Brazil, but not all of them are included here.
   *
   * A comprehensive list of all Brazilian banks and their codes can be found at
   * http://www.buscabanco.org.br/AgenciasBancos.asp
   *
   * @return {String} The bank name
   */
  bank() {
    switch (this.barcode().substr(0, 3)) {
      case '001': return 'Banco do Brasil';
      case '007': return 'BNDES';
      case '033': return 'Santander';
      case '069': return 'Crefisa';
      case '077': return 'Banco Inter';
      case '102': return 'XP Investimentos';
      case '104': return 'Caixa Econômica Federal';
      case '140': return 'Easynvest';
      case '197': return 'Stone';
      case '208': return 'BTG Pactual';
      case '212': return 'Banco Original';
      case '237': return 'Bradesco';
      case '260': return 'Nu Pagamentos';
      case '341': return 'Itaú';
      case '389': return 'Banco Mercantil do Brasil';
      case '422': return 'Banco Safra';
      case '505': return 'Credit Suisse';
      case '633': return 'Banco Rendimento';
      case '652': return 'Itaú Unibanco';
      case '735': return 'Banco Neon';
      case '739': return 'Banco Cetelem';
      case '745': return 'Citibank';
      default: return 'Unknown';
    }
  }

  /**
   * Returns the currency of the bank slip
   *
   * The currency is determined by the currency code, the fourth digit of the
   * barcode. A list of values other than 9 (Brazilian Real) could not be found.
   *
   * @return {String} The currency code, symbol and decimal separator
   */
  currency() {
    switch (this.barcode()[3]) {
      case '9': return { code: 'BRL', symbol: 'R$', decimal: ',' };
      default: return 'Unknown';
    }
  }

  /**
   * Returns the verification digit of the barcode
   *
   * The barcode has its own checksum digit, which is the fifth digit of itself.
   *
   * @return {String} The checksum of the barcode
   */
  checksum() {
    return this.barcode()[4];
  }

  /**
   * Returns the date when the bank slip is due
   *
   * The portion of the barcode ranging from its sixth to its nineth digits
   * represent the number of days since the 7th of October, 1997 up to when the
   * bank slip is good to be paid. Attempting to pay a bank slip after this date
   * may incurr in extra fees.
   *
   * @return {Date} The expiration date of the bank slip
   */
  expirationDate() {
    const refDate = new Date(876236400000); // 1997-10-07 12:00:00 GMT-0300
    const days = this.barcode().substr(5, 4);

    return new Date(refDate.getTime() + (days * 86400000));
  }

  /**
   * Returns the bank slip's nominal amount
   *
   * @return {String} The bank slip's raw amount
   */
  amount() {
    return (this.barcode().substr(9, 10) / 100.0).toFixed(2);
  }

  /**
   * Returns the bank slip's formatted nominal amount
   *
   * @return {String} The bank slip's formatted amount
   */
  prettyAmount() {
    const currency = this.currency();

    if (currency === 'Unknown') {
      return this.amount();
    }

    return `${currency.symbol} ${this.amount().replace('.', currency.decimal)}`;
  }

  /**
   * Renders the bank slip as a child of the provided selector
   *
   * @param {String} selector The selector to the object where the SVG must be
   * appended
   *
   * @see {@link SVG#render}
   */
  toSVG(selector) {
    const stripes = encode(this.barcode());
    return new SVG(stripes).render(selector, this.height);
  }
}

export default Boleto;