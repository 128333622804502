import React from 'react';
import { MenuItem } from '@mui/material';
import 'components/PageNotFound/styles/PageNotFound.css';
import TextDefault from 'components/v2/Text';

interface ItemMenuProps {
  label: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  Image?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  isLogout?: boolean;
}

const ItemMenu = (props: ItemMenuProps): JSX.Element => {
  const { label, onClick, Image, isLogout = false } = props;

  return (
    <MenuItem onClick={onClick} href="#" className={`${isLogout ? 'isLogout' : ''}`}>
      {Image && <Image />}
      <TextDefault text={label} fontSize='large' />
    </MenuItem>
  );
};

export default ItemMenu;
