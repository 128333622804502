import { createContext } from 'react';

export type AppHash = string;
export type LogoUrl = string;
export type RazaoSocial = string;
export type CnpjCpf = string;

export enum CompanyActionsDescription {
  'whatsapp' = 'WhatsApp',
  'email' = 'E-mail',
  'telefone' = 'Telefone',
  'site' = 'Site',
}
export type CompanyActionsType = keyof typeof CompanyActionsDescription;

export enum CompanyStorePaymentMethodIcon {
  'pix' = 'fa-brands fa-pix',
  'boleto' = 'fa-solid fa-barcode',
  'invoiced' = 'fa-solid fa-receipt',
}
export interface CompanyStorePaymentMethod {
  description: string;
  method: 'pix' | 'boleto' | 'invoiced';
}

export interface ICompanyActions {
  filename: string;
  type: CompanyActionsType
}

export interface IEndereco {
  logradouro: string | null;
  numero: string | null;
  bairro: string | null;
  complemento: string | null;
  cidade: string | null;
  estado: string | null;
  cep: string | null;
  lat_lng: string | null;
}

export interface ICompanyDocs {
  enabled: boolean;
}

export interface ICompanyStore {
  enabled: boolean,
  stock: boolean,
  price: boolean,
  cart: boolean,
  cart_payment_methods: CompanyStorePaymentMethod[],
  cart_shipping: boolean,
}

export interface ICompanyOrders {
  enabled: boolean,
}

export interface ICompanyPaymentLink {
  celcoin_public_token: string | null,
}

export interface ICompanyContext {
  actions: ICompanyActions[]
  app_hash: AppHash,
  app_name: string,
  banner_url?: string | undefined,
  cnpj_cpf: CnpjCpf,
  code: string,
  docs: ICompanyDocs,
  endereco: IEndereco,
  isLoading: boolean,
  last_update: string,
  logo_url: LogoUrl,
  marketing_message: string,
  nome_fantasia?: string,
  orders: ICompanyOrders,
  profile_alias: string,
  razao_social: RazaoSocial,
  status: 'success',
  store: ICompanyStore,
  support_message: string,
  timestamp: number,
  type: 'company',
  version: string,
  link_payment: ICompanyPaymentLink,
}

export const CompanyContext = createContext<ICompanyContext | null>(null);
