import { Avatar } from '@mui/material';
import useStyles from './style/empresa';
import { Logger } from 'services';
import { ModalRightProps } from 'store/reducer/modalRight';
import TitleDefault from 'components/v2/Title';
import useCompanyUtils from 'hooks/useCompany';
import TextDefault from 'components/v2/Text';
import ButtonDefault from 'components/v2/Button';

import { ReactComponent as IconeStore } from 'assets/images/icons/ic-store.svg';
import BlockItem from 'components/v2/ListItemInstallment/BlockItem';
import { stringifyAddress } from 'utils/address';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as IconeWhats } from 'assets/images/icons/ic-whatsapp.svg';
import { ReactComponent as IconeSite } from 'assets/images/icons/ic-home.svg';
import { ReactComponent as IconeEmail } from 'assets/images/icons/ic-mail.svg';
import { ReactComponent as IconeTelefone } from 'assets/images/icons/ic-call.svg';
import { ICompanyActions } from 'context/CompanyContext';
import GoogleMaps from 'components/Maps';

export default function ModalEmpresa(props: ModalRightProps): JSX.Element {
  const classes = useStyles();

  const { company } = props;

  Logger.info('company', company);

  const companyUtils = useCompanyUtils();
  const navigate = useNavigate();

  if (!company) return <></>;

  const hasMessageSupport =
    !!('support_message' in company) && company.support_message !== null && company.support_message.trim().length > 0;

  const hasButtonStore = company.store && company.store.enabled === true;

  const contacts = company.actions;

  const handleClickStore = async (): Promise<void> => {
    //analytics.click('redirect_company_store');
    navigate(companyUtils.getCompanyPage(company));
  };

  function getIcon(icon: ICompanyActions['type']): null | ((props: React.SVGProps<SVGSVGElement>) => JSX.Element) {
    switch (icon) {
      case 'email':
        return IconeEmail;
      case 'site':
        return IconeSite;
      case 'telefone':
        return IconeTelefone;
      case 'whatsapp':
        return IconeWhats;
      default:
        return null;
    }
  }

  return (
    <div className={`${classes.root}`}>
      <div className={classes.header}>
        <Avatar src={company.logo_url} variant={'circular'} className={classes.imageLogo}></Avatar>
        <div>
          <TitleDefault text={companyUtils.getCompanyName(company)} typeFont="modalTitle" />
          <TextDefault text="CNPJ" fontFamily="openSans" fontSize="large" textType="body" />
          <TextDefault text={company.cnpj_cpf} fontFamily="openSans" fontSize="large" textType="body" />
        </div>
      </div>
      {hasButtonStore && (
        <div className={classes.actions}>
          <ButtonDefault
            text="Ver catálogo"
            theme="financas"
            styled="tonal"
            fontSize="large"
            display="block"
            Image={IconeStore}
            imageSize="small"
            onClick={handleClickStore}
          ></ButtonDefault>
        </div>
      )}
      <div className={classes.body}>
        {!!company.endereco && !!company.endereco.logradouro && (
          <BlockItem title="Endereço" text={stringifyAddress(company.endereco)}></BlockItem>
        )}
        {hasMessageSupport && <BlockItem title="Horário de atendimento" text={company.support_message}></BlockItem>}
        {contacts.map((contact, i) => {
          const Icon = getIcon(contact.type);
          if (!Icon) return null;
          return (
            <ButtonDefault
              key={i}
              text={contact.filename}
              Image={Icon}
              fontSize="large"
              imageSize="small"
              theme="financas"
            ></ButtonDefault>
          );
        })}
      </div>
      {!!company.endereco && !!company.endereco.logradouro && (
        <GoogleMaps
          address={company.endereco}
          zoom={17}
          className={classes.map}
          renderOnFail={<div className={classes.mapImage} />}
        />
      )}
    </div>
  );
}
