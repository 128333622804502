import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0 16px',
    marginBottom: 16,
  },
}));

export default useStyles;
