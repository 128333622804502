import { Button } from '@mui/material';
import useStyles from './style';

import icon_crm from 'assets/images/icons/icon-filter-list.svg';
import icon_vendas from 'assets/images/icons/icon-finance.svg';
import icon_servicos from 'assets/images/icons/icon-checklist.svg';
import icon_compras from 'assets/images/icons/icon-package.svg';
import icon_financas from 'assets/images/icons/icon-paid.svg';
import icon_contador from 'assets/images/icons/icon-person.svg';

type ItemMenuIcon = 'crm' | 'vendas' | 'servicos' | 'compras' | 'financas' | 'contador';

const ImagesItemMenu = {
  crm: icon_crm,
  vendas: icon_vendas,
  servicos: icon_servicos,
  compras: icon_compras,
  financas: icon_financas,
  contador: icon_contador,
};

interface ItemMenuProps {
  menu: ItemMenuIcon;
  title: string;
  active?: boolean;
  onClick?: () => void;
}

const ItemMenu = (props: ItemMenuProps): JSX.Element => {
  const { menu, title, active = false, onClick } = props;

  const classes = useStyles();

  return (
    <Button
      disableRipple
      title={title}
      onClick={onClick}
      className={`${classes.buttonMenu} ${classes[`menu${menu}`]} ${active ? 'active' : ''}`}
    >
      <img src={ImagesItemMenu[menu]}></img>
    </Button>
  );
};

export default ItemMenu;
