import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  tooltip: {
    borderRadius: '0',
    background: 'none',
    margin: 0,
    padding: 0,
    maxWidth: '100%',
    marginTop: '-1px !important',
    backgroundColor: theme.palette.secondary.main,
    // backgroundColor: 'red',
    color: theme.palette.primary[800],
    zIndex: '1099',
  },
  menuItem: {
    height: '70px',
    textTransform: 'none',
    transition: 'all 0.3s',
    padding: '6px 8px',
    heigth: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuItemDestaque: {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.secondary.dark,
    textDecorationThickness: '4px',
    textUnderlineOffset: '4px',
    animation: '$pulseColor 2s cubic-bezier(0.95, 1, 0.5, 1) 0s infinite alternate',
  },
  '@keyframes pulseColor': {
    '80%': {
      textUnderlineOffset: '4px',
    },
    '100%': {
      textUnderlineOffset: '10px',
    },
  },
  containerTop: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% + 18px)',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% + 45px)',
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc(100% + 50px)',
    },
  },
}));

export default useStyles;
