import { Dialog } from '@mui/material';

import { LeftMenuOption } from 'interfaces/menu-items';

import ComprasItem from './menus/compras';
import ContadorItem from './menus/contador';
import CrmItem from './menus/crm';
import FinancasItem from './menus/financas';
import ServicosItem from './menus/servicos';
import VendasItem from './menus/vendas';

import Title from './Title';
import useStyles from './style';

interface FloatingMenuProps {
  closeMenu: () => void;
  currentFloatingMenu: LeftMenuOption;
}

const FloatingMenu = ({ closeMenu, currentFloatingMenu }: FloatingMenuProps): JSX.Element => {
  const classes = useStyles();

  const menus: Record<LeftMenuOption, JSX.Element> = {
    compras: <ComprasItem />,
    crm: <CrmItem />,
    contador: <ContadorItem />,
    financas: <FinancasItem closeMenu={closeMenu} />,
    servicos: <ServicosItem />,
    vendas: <VendasItem closeMenu={closeMenu} />,
  };

  return (
    <Dialog
      open
      fullScreen
      onClose={closeMenu}
      className={classes.dialogRoot}
      slotProps={{ backdrop: { invisible: true } }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.30), 1px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        },
      }}
    >
      <div className={classes.wrapper}>
        <Title activeMenu={currentFloatingMenu} />
        {menus[currentFloatingMenu]}
      </div>
    </Dialog>
  );
};

export default FloatingMenu;
