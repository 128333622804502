import {
  BaseFontFamily,
  BaseFontSize,
  BaseFontWeight,
  BaseTheme,
} from 'interfaces/base-layout';
import useStyles from './style';

export type TextWrap = 'auto' | 'anywhere';
export type TextType = 'title' | 'label' | 'display' | 'headline' | 'body';

interface ButtonProps {
  text: string;
  fontFamily?: BaseFontFamily;
  fontSize?: BaseFontSize;
  textType?: TextType;
  surfaceVariant?: boolean;
  fontWeight?: BaseFontWeight;
  textWrap?: TextWrap;
  theme?: BaseTheme;
}

export default function TextDefault(props: ButtonProps): JSX.Element {
  const {
    text,
    fontFamily = 'poppins',
    fontSize = 'medium',
    textType = 'label',
    surfaceVariant = false,
    fontWeight = '400',
    textWrap = 'auto',
    theme = '',
  } = props;

  const classes = useStyles();

  const surfaceVariantClass = surfaceVariant ? 'surfaceVariant' : '';
  const fontWeightClass = `font${fontWeight}`;
  const textWrapClass = `wrap${textWrap}`;

  return (
    <p
      className={`
        ${classes.text} 
      ${textType} 
      ${fontSize} 
      ${fontFamily} 
      ${surfaceVariantClass} 
      ${fontWeightClass} 
      ${textWrapClass}
      ${theme}`}
    >
      {text}
    </p>
  );
}
