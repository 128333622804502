import { useNavigate } from 'react-router-dom';

import Button from 'components/v2/Button';

import bankIcon from 'assets/images/left-menu/financas/bank.png';
import currencyExchangeIcon from 'assets/images/left-menu/financas/currency-exchange.png';
import moneyIcon from 'assets/images/left-menu/financas/money.png';
import receiptIcon from 'assets/images/left-menu/financas/receipt.png';

import CardGrid from '../../CardGrid';
import CardTitle from '../../CardTitle';
import GridItem from '../../GridItem';
import MenuCard from '../../MenuCard';
import MenuCardSmall from '../../MenuCardSmall';
import useStyles from './style';
import useAnalytics from 'hooks/useAnalytics';

interface FinancasItemProps {
  closeMenu: () => void;
}

const FinancasItem = ({ closeMenu }: FinancasItemProps): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const analytics = useAnalytics('menu', 'portal');

  const handleFinancialHistoryClick = (): void => {
    analytics.click('financas_card1');
    navigate('/historico-financeiro');
    closeMenu();
  };

  const handleSearchCnpjCpf = (): void => {
    analytics.click('financas_card2');
    navigate('/consulta-cnpj-cpf');
    closeMenu();
  };

  const handleOmieClick = (): void => {
    analytics.click('financas_cta');
    window
      .open('https://www.omie.com.br/lp/plg/?utm_source=portal&utm_medium=plg&utm_campaign=item_menu', '_blank')
      ?.focus();
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.smallCardWrapper}>
        <MenuCardSmall
          onClick={handleFinancialHistoryClick}
          text="Visualize suas contas a pagar"
          title="Histórico financeiro"
          theme="financas"
        />
        <MenuCardSmall
          onClick={handleSearchCnpjCpf}
          text="Aumente a segurança das suas vendas"
          title="Consulta de restrição de CPF/CNPJ"
          theme="financas"
        />
      </div>

      <div className={classes.cardWrapper}>
        <MenuCard>
          {/* eslint-disable-next-line max-len */}
          <CardTitle text="Tenha mais controle sobre seu planejamento financeiro, conciliação bancária e antecipação de recebíveis em um único lugar." />

          <CardGrid>
            <GridItem
              icon={<img alt="Papel representando relatório" src={receiptIcon} />}
              text="Relatório financeiro diário"
            />
            <GridItem
              icon={<img alt="Cédula de dinheiro com duas setas representando fluxo" src={currencyExchangeIcon} />}
              text="Planejamento de fluxo de caixa"
            />
            <GridItem
              icon={<img alt="Papel representando contas" src={receiptIcon} />}
              text="Contas a pagar e a receber"
            />
            <GridItem icon={<img alt="Agência bancária" src={bankIcon} />} text="Conciliação bancária" />
            <GridItem icon={<img alt="Cédula de dinheiro" src={moneyIcon} />} text="Omie.Cash" />
          </CardGrid>

          <Button
            text="Conheça o sistema de gestão Omie"
            theme="financas"
            styled="tonal"
            fontSize="large"
            onClick={handleOmieClick}
          />
        </MenuCard>
      </div>
    </div>
  );
};

export default FinancasItem;
