import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 8,
  },
  cardWrapper: {
    width: 583,
  },
}));

export default useStyles;
