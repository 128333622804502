import Text from 'components/v2/Text';

import useStyles from './style';

interface GridItemProps {
  text: string;
  icon: JSX.Element;
}

const GridItem = ({ text, icon }: GridItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {icon}
      <Text text={text} textType="label" fontFamily="openSans" fontWeight="600" theme="omie" />
    </div>
  );
};

export default GridItem;
