import omieVariables from 'assets/scss/_themes-vars-v2.module.scss';
import { ThemeFonts } from 'typings/v2/theme';

const fonts: ThemeFonts = {
  display: {
    fontSize: {
      small: omieVariables.displaySizeSmall,
      medium: omieVariables.displaySizeMedium,
      large: omieVariables.displaySizeLarge,
    },
    lineHeight: {
      small: omieVariables.displayLineHeightSmall,
      medium: omieVariables.displayLineHeightMedium,
      large: omieVariables.displayLineHeightLarge,
    },
  },
  headline: {
    fontSize: {
      small: omieVariables.headlineSizeSmall,
      medium: omieVariables.headlineSizeMedium,
      large: omieVariables.headlineSizeLarge,
    },
    lineHeight: {
      small: omieVariables.headlineLineHeightSmall,
      medium: omieVariables.headlineLineHeightMedium,
      large: omieVariables.headlineLineHeightLarge,
    },
  },
  title: {
    fontSize: {
      small: omieVariables.titleSizeSmall,
      medium: omieVariables.titleSizeMedium,
      large: omieVariables.titleSizeLarge,
    },
    lineHeight: {
      small: omieVariables.titleLineHeightSmall,
      medium: omieVariables.titleLineHeightMedium,
      large: omieVariables.titleLineHeightLarge,
    },
  },
  label: {
    fontSize: {
      small: omieVariables.labelSizeSmall,
      medium: omieVariables.labelSizeMedium,
      large: omieVariables.labelSizeLarge,
    },
    lineHeight: {
      small: omieVariables.labelLineHeightSmall,
      medium: omieVariables.labelLineHeightMedium,
      large: omieVariables.labelLineHeightLarge,
    },
  },
  body: { 
    fontSize: {
      small: omieVariables.bodySizeSmall,
      medium: omieVariables.bodySizeMedium,
      large: omieVariables.bodySizeLarge,
    },
    lineHeight: {
      small: 'normal',
      medium: 'normal',
      large: 'normal',
    },
  },
};

export default fonts;
