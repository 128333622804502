import ListItem from 'components/v2/ListItem';

import ModalTitle from '../title';

interface ModalAjudaDocumentoDefaultProps {
  navigateToReadPermissionModal: () => void;
  navigateToSaleIssueModal: () => void;
  navigateToPortalIssueModal: () => void;
}

export default function ModalAjudaDocumentoDefault({
  navigateToReadPermissionModal,
  navigateToSaleIssueModal,
  navigateToPortalIssueModal,
}: ModalAjudaDocumentoDefaultProps): JSX.Element {
  return (
    <>
      <ModalTitle>Como podemos te ajudar?</ModalTitle>

      <ListItem
        title="Quem pode ver este documento?"
        subTitle="Informações sobre acesso, permissão e prazos de visualização"
        onClick={navigateToReadPermissionModal}
      />
      <ListItem
        title="Tenho um problema com uma venda ou serviço"
        subTitle="Problemas com valores, situação de parcelas e formas de pagamento"
        onClick={navigateToSaleIssueModal}
      />
      <ListItem
        title="Tenho um problema técnico com o portal"
        subTitle="Problemas para acessar, visualizar informações ou interagir com o portal Omie"
        showSeparator={false}
        onClick={navigateToPortalIssueModal}
      />
    </>
  );
}
