import Text from 'components/v2/Text';

import useStyles from './style';

interface ModalAjudaTitleProps {
  children: string;
}

export default function ModalAjudaTitle({ children }: ModalAjudaTitleProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Text text={children} textType="headline" />
    </div>
  );
}
