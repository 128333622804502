import Text from 'components/v2/Text';
import { LeftMenuOption } from 'interfaces/menu-items';

import icon_crm from 'assets/images/icons/icon-filter-list.svg';
import icon_vendas from 'assets/images/icons/icon-finance.svg';
import icon_servicos from 'assets/images/icons/icon-checklist.svg';
import icon_compras from 'assets/images/icons/icon-package.svg';
import icon_financas from 'assets/images/icons/icon-paid.svg';
import icon_contador from 'assets/images/icons/icon-person.svg';

import useStyles from './style';

interface Title {
  text: string;
  icon: JSX.Element;
}

interface MenuTitleProps {
  activeMenu: LeftMenuOption;
}

const MenuTitle = ({ activeMenu }: MenuTitleProps): JSX.Element => {
  const classes = useStyles();

  const titles: Record<LeftMenuOption, Title> = {
    compras: {
      icon: <img src={icon_compras} />,
      text: 'Compras, estoque e produção',
    },
    crm: {
      icon: <img src={icon_crm} />,
      text: 'CRM',
    },
    contador: {
      icon: <img src={icon_contador} />,
      text: 'Painel do contador',
    },
    financas: {
      icon: <img src={icon_financas} />,
      text: 'Finanças',
    },
    servicos: {
      icon: <img src={icon_servicos} />,
      text: 'Serviços e NFS-e',
    },
    vendas: {
      icon: <img src={icon_vendas} />,
      text: 'Vendas e NF-e',
    },
  };

  const { icon, text } = titles[activeMenu];

  return (
    <div className={`${classes.wrapper} ${activeMenu}`}>
      {icon}
      <Text text={text} fontWeight="500" textType="title" theme={activeMenu} />
    </div>
  );
};

export default MenuTitle;
