import ModalTitle from './title';
import ModalParagraph from './paragraph';

import useClasses from './style';

export default function ModalAjudaPortal(): JSX.Element {
  const classes = useClasses();

  return (
    <>
      <ModalTitle>Tenho um problema técnico com o portal</ModalTitle>

      <ModalParagraph>
        Se você tem dúvidas sobre o Portal ou problemas técnicos (como mensagens de erro ou falhas para visualizar ou
        baixar documentos), entre em contato com a Omie pelo e-mail abaixo:
      </ModalParagraph>

      <a className={classes.emailLink} href="mailto:ajuda.portal@omie.com.br">
        ajuda.portal@omie.com.br
      </a>
    </>
  );
}
