import useStyles from './style';

type TypeFont = 'h1' | 'h2' | 'modalTitle';

interface TitleProps {
  text: string;
  typeFont?: TypeFont;
  marginBottom?: string;
}

export default function TitleDefault(props: TitleProps): JSX.Element {
  const { text, typeFont = 'h1', marginBottom = '0px' } = props;

  const classes = useStyles();

  return (
    <p className={`${classes.title} ${typeFont}`} style={{ marginBottom: marginBottom }}>
      {text}
    </p>
  );
}
