import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  card: {
    cursor: 'pointer',
    padding: 16,
    maxWidth: 202,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    borderRadius: 8,
    background: theme.palette2.omie.surfaces.surfaceContainer1,
    boxShadow: theme.palette2.shadows.boxShadow,

    '&.crm': {
      color: theme.palette2.crm.backgrounds.onBackground,
      border: theme.palette2.crm.backgrounds.onBackground,
    },

    '&.vendas': {
      color: theme.palette2.vendas.backgrounds.onBackground,
      border: `1px solid ${theme.palette2.vendas.outlines.outline}`,
    },

    '&.servicos': {
      color: theme.palette2.servicos.backgrounds.onBackground,
    },

    '&.compras': {
      color: theme.palette2.compras.backgrounds.onBackground,
    },

    '&.financas': {
      border: `1px solid ${theme.palette2.financas.outlines.outline}`,
    },

    '&.contador': {
      color: theme.palette2.contador.backgrounds.onBackground,
    },
  },
}));

export default useStyles;
