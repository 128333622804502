import { DocumentParcela } from 'context/DocumentContext';
import { formatDate, formatFloatToRealCurrency } from './converts';

export function getValueInstallment(parcela: DocumentParcela, tipoRetorno: 'number' | 'string'): number | string {
  const value = parcela.situacao === '1' || parcela.situacao === '3' ? parcela.valor_liquido : parcela.valor_titulo;
  return tipoRetorno === 'number' ? value : formatFloatToRealCurrency(value);
}

export function getStatusTextInstallment(parcela: DocumentParcela): string {
  if (parcela.situacao === '2' && parcela.data_liquidacao) {
    return 'Pago em ' + formatDate(parcela.data_liquidacao);
  } else if (parcela.situacao === '4' && parcela.data_cancelamento) {
    return 'Cancelado em ' + formatDate(parcela.data_cancelamento);
  } else {
    return 'Vencimento em ' + formatDate(parcela.data_vencimento);
  }
}

export function getPaidValue(parcela: DocumentParcela): number {
  return (parcela.situacao === '2') //liquidado
    ? parcela.valor_pago + parcela.juros + parcela.multa - parcela.desconto
    : parcela.valor_pago;
}

export const STATUS_PARCELA_EMPRESA_TEXT = {
  '1': 'Em aberto',
  '2': 'Pago',
  '3': 'Atrasado',
  '4': 'Cancelado',
  '5': 'Autorizado',
};

export const STATUS_PARCELA_EMPRESA_THEME_NAME = {
  '1': 'open',
  '2': 'paid',
  '3': 'expired',
  '4': 'canceled',
  '5': 'authorized',
};

export const STATUS_PARCELA_TEXT = {
  '1': 'Pronta para pagar',
  '2': 'Paga',
  '3': 'Atrasada',
  '4': 'Cancelada',
  '5': 'Autorizada',
};

export const STATUS_PARCELA_THEME_NAME = {
  '1': 'open',
  '2': 'paid',
  '3': 'expired',
  '4': 'canceled',
  '5': 'authorized',
};
