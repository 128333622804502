import React from 'react';
import PropTypes from 'prop-types';
import Boleto from 'utils/boleto';

const Barcode = (props) => {

  const { barcode, height } = props;
  const barcodeSvg = new Boleto(barcode, height, false).toSVG();

  return (
    <div
      dangerouslySetInnerHTML={{ __html: barcodeSvg }}
      style={{
        height: height,
        position: 'relative',
        top: `-${height / 4}px`,  
      }}
    />
  );
};

Barcode.defaultProps = {
  height: 150,
};

Barcode.propTypes = {
  barcode: PropTypes.string.isRequired,
  height: PropTypes.number,
};

export default Barcode;