import TooltipConsultaRestricao from 'components/Tooltips/ConsultaRestricao';
import TooltipCreditoParcelado from 'components/Tooltips/CreditoParcelado';
import { MenuItemGroup } from 'interfaces/menu-items';

const itemsPortal: MenuItemGroup = {
  id: 'portal',
  title: '',
  type: 'group',
  children: [
    // {
    //   title: 'Meus Aplicativos',
    //   type: 'item',
    //   url: '/meus-aplicativos',
    //   icon: 'table-cells',
    //   breadcrumbs: false,
    //   onlyAuthenticated: true,
    //   highlight: false,
    //   requiredLogin: true,
    //   analytics: 'my_apps',
    // },
    // {
    //   title: 'Painel',
    //   type: 'item',
    //   url: '/home',
    //   icon: 'building',
    //   breadcrumbs: false,
    //   onlyAuthenticated: false,
    //   highlight: false,
    //   requiredLogin: true,
    //   analytics: 'panel',
    // },
    {
      title: 'Pedidos',
      type: 'item',
      url: '/meus-pedidos',
      icon: 'list',
      breadcrumbs: false,
      onlyAuthenticated: false,
      highlight: false,
      requiredLogin: true,
      analytics: 'orders',
    },
    // {
    //   title: 'Histórico Financeiro',
    //   type: 'item',
    //   url: '/historico-financeiro',
    //   icon: 'receipt',
    //   breadcrumbs: false,
    //   onlyAuthenticated: false,
    //   highlight: false,
    //   requiredLogin: true,
    //   analytics: 'financial_history',
    // },
    // {
    //   title: 'Consulta de CNPJ/CPF',
    //   type: 'item',
    //   url: '/consulta-cnpj-cpf',
    //   icon: 'search',
    //   breadcrumbs: false,
    //   tooltip: <TooltipConsultaRestricao />,
    //   onlyAuthenticated: false,
    //   highlight: true,
    //   requiredLogin: false,
    //   analytics: 'constraint_search',
    // },
    // {
    //   title: 'Para seu negócio',
    //   type: 'item',
    //   url: 'https://www.omie.com.br/lp/plg',
    //   external: true,
    //   icon: 'briefcase',
    //   breadcrumbs: false,
    //   onlyAuthenticated: false,
    //   highlight: false,
    //   requiredLogin: false,
    //   analytics: 'landing_page',
    // },
    // {
    //   title: 'Crédito Parcelado',
    //   type: 'item',
    //   url: '/credito-parcelado',
    //   icon: 'comments-dollar',
    //   breadcrumbs: false,
    //   //tooltip: <TooltipCreditoParcelado />,
    //   onlyAuthenticated: false,
    //   highlight: false,
    //   requiredLogin: false,
    //   analytics: 'installment_credit',
    // },
  ],
};

export default itemsPortal;
