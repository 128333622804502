import React, { useContext } from 'react';
import Barcode from 'components/Barcode';
import { CompanyContext } from 'context/CompanyContext';
import useAnalytics from 'hooks/useAnalytics';
import { DocumentParcelaBoleto } from 'context/DocumentContext';
import { ReactComponent as IconeCopiar } from 'assets/images/icons/ic-copy.svg';
import { ReactComponent as IconeDownload } from 'assets/images/icons/ic-download.svg';
import { copyToClipboard } from 'utils';
import TextDefault from 'components/v2/Text';
import ButtonDefault from 'components/v2/Button';
import useStyles from './style/detalhe';

interface DetalheBoletoProps {
  data: DocumentParcelaBoleto;
}

const DetalheBoleto = (props: DetalheBoletoProps): JSX.Element => {
  const { data } = props;

  const company = useContext(CompanyContext);
  const analytics = useAnalytics('action_button_popup_parcela', 'portal');

  const classes = useStyles();

  const handleDownload = (): void => {
    analytics.download(data.id, { app_hash: company ? company.app_hash : null });
    window.open(data.url, '_blank')?.focus();
  };

  const handleCopy = (): void => {
    analytics.click('copiar código de barras', { app_hash: company?.app_hash });
    copyToClipboard(data.data.codigo_barras, 'Código copiado com sucesso.');
  };

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        {data.url && (
          <ButtonDefault
            text="Baixar cobrança"
            theme="financas"
            styled="text"
            fontSize="large"
            Image={IconeDownload}
            onClick={handleDownload}
            imageSize="small"
          ></ButtonDefault>
        )}
        <ButtonDefault
          text="Copiar linha digitável"
          theme="financas"
          styled="tonal"
          fontSize="large"
          Image={IconeCopiar}
          onClick={handleCopy}
          imageSize="small"
        ></ButtonDefault>
      </div>
      <div className={classes.details}>
        <div className={classes.doc}>
          <TextDefault text="Linha digitável" fontFamily="openSans" fontWeight="600" textType="label" />
          <TextDefault text={data.data.codigo_barras} fontFamily="openSans" textType="body" textWrap="anywhere" />
        </div>

        <div className={classes.image} style={{ marginBottom: '-19px' }}>
          <TextDefault text="Código de barras" fontFamily="openSans" fontWeight="600" textType="label" />
          <Barcode barcode={data.data.codigo_barras} height={76} />
        </div>
      </div>
    </div>
  );
};

export default DetalheBoleto;
