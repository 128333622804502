import { MenuItemGroup } from 'interfaces/menu-items';

const itemsPortalV2: MenuItemGroup = {
  id: 'portal',
  title: '',
  type: 'group',
  children: [
    {
      title: 'Meus Aplicativos',
      type: 'item',
      url: '/meus-aplicativos',
      icon: 'table-cells',
      breadcrumbs: false,
      onlyAuthenticated: true,
      highlight: false,
      requiredLogin: true,
      analytics: 'my_apps',
    },
  ],
};

export default itemsPortalV2;
