import Text from 'components/v2/Text';

import useStyles from './style';

interface CardTitleProps {
  text: string;
}

const CardTitle = ({ text }: CardTitleProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Text text={text} textType="title" fontWeight="500" theme="omie" />
    </div>
  );
};

export default CardTitle;
