import BlockItem from '../ListItemInstallment/BlockItem';
import { DocumentParcela } from 'context/DocumentContext';
import { BaseOrderStatus } from 'interfaces/base-layout';
import { STATUS_PARCELA_TEXT, STATUS_PARCELA_THEME_NAME } from 'utils/status';

interface StatusInstallmentProps {
  parcela: DocumentParcela;
}

export default function StatusInstallment(props: StatusInstallmentProps): JSX.Element {
  const { parcela } = props;

  const themeStatus = STATUS_PARCELA_THEME_NAME[parcela.situacao];
  const parcelaText = STATUS_PARCELA_TEXT[parcela.situacao];

  return (
    <BlockItem text={parcelaText} isStatus={true} status={themeStatus as BaseOrderStatus} isModal={true}></BlockItem>
  );
}
