import ModalTitle from '../title';
import ModalParagraph from '../paragraph';

export default function ModalAjudaHistoricoVendaOuServico(): JSX.Element {
  return (
    <>
      <ModalTitle>Tenho um problema com uma venda ou serviço</ModalTitle>

      <ModalParagraph>
        Todas as informações dos documentos fiscais e de cobrança são de responsabilidade do fornecedor.
      </ModalParagraph>

      <ModalParagraph>
        Entre em contato direto com a empresa que gerou o documento fiscal ou de cobrança no Portal Omie.
      </ModalParagraph>
    </>
  );
}
