import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 36,
    display: 'inline-flex',
    alignItems: 'center',
    gap: 4,
  },
}));

export default useStyles;
