import { useState } from 'react';
import { Grid } from '@mui/material';

import { LeftMenuOption } from 'interfaces/menu-items';

import ItemMenu from './ItemMenu';
import FloatingMenu from './FloatingMenu';
import useStyles from './style';
import useAuth from 'hooks/useAuth';
import useAnalytics from 'hooks/useAnalytics';

interface LeftNavProps {
  isBannerVisible: boolean;
}

const LeftNav = (props: LeftNavProps): JSX.Element => {
  const { isBannerVisible } = props;
  const auth = useAuth();

  const [floatingMenuOpen, setFloatingMenuOpen] = useState<LeftMenuOption | null>(null);
  const classes = useStyles();

  const analytics = useAnalytics('menu', 'portal');

  const openFloatingMenu = (option: LeftMenuOption): void => {
    analytics.click(option);
    setFloatingMenuOpen(option);
  };

  const closeFloatingMenu = (): void => {
    setFloatingMenuOpen(null);
  };

  return !auth.isAuthenticated || (auth.user !== null && auth.user.myApps === 0) ? (
    <>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        className={classes.leftnav}
        sx={{
          marginTop: isBannerVisible ? '104px!important' : '56px',
        }}
      >
        <ItemMenu
          active={floatingMenuOpen === 'crm'}
          menu="crm"
          title="CRM"
          onClick={(): void => openFloatingMenu('crm')}
        />
        <ItemMenu
          active={floatingMenuOpen === 'vendas'}
          menu="vendas"
          title="Vendas e NF-e"
          onClick={(): void => openFloatingMenu('vendas')}
        />
        <ItemMenu
          active={floatingMenuOpen === 'servicos'}
          menu="servicos"
          title="Serviços e NFS-e"
          onClick={(): void => openFloatingMenu('servicos')}
        />
        <ItemMenu
          active={floatingMenuOpen === 'compras'}
          menu="compras"
          title="Compras, estoque e produção"
          onClick={(): void => openFloatingMenu('compras')}
        />
        <ItemMenu active menu="financas" title="Finanças" onClick={(): void => openFloatingMenu('financas')} />
        <ItemMenu
          active={floatingMenuOpen === 'contador'}
          menu="contador"
          title="Painel do contador"
          onClick={(): void => openFloatingMenu('contador')}
        />
      </Grid>

      {floatingMenuOpen && <FloatingMenu currentFloatingMenu={floatingMenuOpen} closeMenu={closeFloatingMenu} />}
    </>
  ) : (
    <></>
  );
};

export default LeftNav;
