import { ReactNode } from 'react';

import useStyles from './style';

interface MenuCardProps {
  children: ReactNode;
}

const MenuCard = ({ children }: MenuCardProps): JSX.Element => {
  const classes = useStyles();

  return <div className={classes.wrapper}>{children}</div>;
};

export default MenuCard;
