import useStyles from './style';
import TextDefault from '../Text';

interface ListItemResumeProps {
  text: string;
  value?: string;
  isBold?: boolean;
  isTitle?: boolean;
}

export default function ListItemResume(props: ListItemResumeProps): JSX.Element {
  const { text, value = '', isBold = false, isTitle = false } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextDefault
        text={text}
        fontFamily="openSans"
        textType={isTitle ? 'label' : 'body'}
        fontWeight={isTitle ? '600' : isBold ? '700' : '400'}
      ></TextDefault>
      {value !== '' && (
        <TextDefault
          text={value}
          fontFamily="openSans"
          textType={'body'}
        ></TextDefault>
      )}
    </div>
  );
}
