import { createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import colors from 'assets/scss/_themes-vars.module.scss';
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import themeStatusPayment from './statusPayments';
import themeStatusOrder from './statusOrders';
import type { CustomizationState } from 'store/reducer/customization';
import themeStatusCredit from './statusCredits';
import paletteLight from './v2/light';
import paletteDark from './v2/dark';
import fonts from './v2/fonts';

export type PaymentStatusType = 'open' | 'paid' | 'expired' | 'canceled' | 'authorized';

export type IThemeOptionColor = string;

export interface IThemeOptionColors {
  [key: string]: IThemeOptionColor;
}

export interface IThemeOption {
  colors: IThemeOptionColors;
  heading: IThemeOptionColor;
  paper: IThemeOptionColor;
  backgroundDefault: IThemeOptionColor;
  background: IThemeOptionColor;
  darkTextPrimary: IThemeOptionColor;
  darkTextSecondary: IThemeOptionColor;
  textDark: IThemeOptionColor;
  menuSelected: IThemeOptionColor;
  menuSelectedBack: IThemeOptionColor;
  divider: IThemeOptionColor;
  customization: CustomizationState;
}

export function theme(customization: CustomizationState): Theme {
  const color = colors;

  const palette = customization.theme === 'light' ? paletteLight : paletteDark;

  const themeOption: IThemeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    customization,
  };

  return createTheme({
    direction: 'ltr',
    palette: themePalette(themeOption),
    palette2: palette,
    fonts: fonts,
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: themeTypography(themeOption),
    paymentStatus: themeStatusPayment(themeOption),
    orderStatus: themeStatusOrder(themeOption),
    creditStatus: themeStatusCredit(themeOption),
    components: componentStyleOverrides(themeOption),
  });
}

export default theme;
export type IThemeCustom = ReturnType<typeof theme>;