import { ICompanyContext } from 'context/CompanyContext';
import { CompanyProduct } from 'interfaces';
import { matchAllSupportedOldBrowsers } from 'utils';

interface IUseCompanyUtils {
  createIdProvider: (app_hash: ICompanyContext['app_hash'], app_name: ICompanyContext['app_name']) => string,
  getCompanyName: (companyData: ICompanyContext) => string,
  getCompanyPage: (companyData: ICompanyContext) => string,
  enabledBuyProduct: (companyData: ICompanyContext, product: CompanyProduct) => boolean,
}

export default function useCompanyUtils(): IUseCompanyUtils {

  function createIdProvider(
    app_hash: ICompanyContext['app_hash'],
    app_name: ICompanyContext['app_name'],
  ): string {
    const nameNormalized = app_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const matchAll = matchAllSupportedOldBrowsers(nameNormalized, /[A-Za-z0-9 ]/g);
    const nameCleaned = matchAll.join('');
    const nameSplitted = nameCleaned.split(' ');
    const name = nameSplitted[0].trim().toLowerCase();
    return `${name}-${app_hash}`;
  }

  function getCompanyName(companyData: ICompanyContext): string {
    if (companyData.nome_fantasia) return companyData.nome_fantasia;
    if (companyData.razao_social) return companyData.razao_social;
    return '';
  }

  function getCompanyPage(companyData: ICompanyContext): string {
    const providerId = createIdProvider(companyData.app_hash, companyData.app_name);
    return `/@${providerId}`;
  }

  function enabledBuyProduct(companyData: ICompanyContext, product: CompanyProduct): boolean {
    if (!companyData.store) return false;
    if (companyData.store.cart !== true) return false;
    if (companyData.store.stock === true && product.stock?.qty === 0) return false;
    if (!product.price) return false;
    if (product.price.current === 0) return false;

    return true;
  }

  return {
    createIdProvider,
    getCompanyName,
    getCompanyPage,
    enabledBuyProduct,
  };

}