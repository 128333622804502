import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0 16px',
    marginBottom: 32,

    '@media(min-width: 500px)': {
      marginBottom: 48,
    },
  },
}));

export default useStyles;
