import { ReactNode } from 'react';

import useStyles from './style';

interface CardGridProps {
  children: ReactNode;
}

const CardGrid = ({ children }: CardGridProps): JSX.Element => {
  const classes = useStyles();

  return <div className={classes.wrapper}>{children}</div>;
};

export default CardGrid;
