import { Box } from '@mui/material';
import { MyApps } from 'interfaces';
import TopBanner from 'layout/Header/TopBanner';
import TopBannerHome from 'layout/Header/TopBannerHome';
import useStyles from './style';

interface BannersProp {
  isTrial: boolean;
  appTrial?: MyApps;
  isTrainingOnline: boolean;
  isVisibleBannerComercial: boolean;
}

export default function Banners(props: BannersProp): JSX.Element {

  const { isTrial, appTrial, isTrainingOnline, isVisibleBannerComercial } = props;

  const classes = useStyles();

  return (
    <Box className={classes.bannerContainer}>
      <TopBanner isTrial={isTrial} app={appTrial} isTrainingOnline={isTrainingOnline} />
      <TopBannerHome isVisibleBannerComercial={isVisibleBannerComercial} />
    </Box>
  );
}
