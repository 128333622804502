import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  blockItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginRight: '10px',
  },
  title: {
    color: theme.palette2.omie.surfaces.onSurfaceVariant,
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '0.4px',
    margin: '0px',
  },
  text: {
    color: theme.palette2.omie.surfaces.onSurface,
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '0.25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '0px',

    '&.isStatus': {
      minWidth: '140px',
    },
    '&.isModal': {
      fontFamily: 'Poppins',
      letterSpacing: '0.1px',
      lineHeight: '20px',
      minWidth: '0px',

      '&.open': {
        color: theme.palette2.omie.alerts.alert,
      },
      '&.paid': {
        color: theme.palette2.omie.successes.success,
      },
      '&.expired': {
        color: theme.palette2.omie.errors.error,
      },
      '&.canceled': {
        color: theme.palette2.omie.outlines.outline,
      },
      '&.authorized': {
        color: theme.palette2.omie.successes.success,
      },
    },
  },
  icon: {
    width: '16px',
    height: '16px',
  },
  displayIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

export default useStyles;