import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '32px',
    marginTop: '-18px',
    padding: '0px 24px 32px 24px',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
  },
  headerText: {
    textAlign: 'center',
  },


  resume: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    borderTop: `1px solid ${theme.palette2.omie.outlines.outlineVariant}`,
    borderBottom: `1px solid ${theme.palette2.omie.outlines.outlineVariant}`,
  },
}));

export default useStyles;