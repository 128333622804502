import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '100px',
    height: '40px',
    color: theme.palette2.omie.surfaces.onSurfaceVariant,
    textTransform: 'none',
    border: '1px solid transparent',
    display: 'inline-flex',

    '& *': {
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },

    '&.fontsmall svg': {
      width: '18px',
    },

    '&.block': {
      width: '100%',
    },

    '&.large': {
      padding: '8px 16px',
    },

    '&.medium': {
      padding: '4px 12px',
    },

    '&.small': {
      padding: '0px 8px',
    },

    '&.crm': {
      '&.filled': {
        background: theme.palette2.crm.primaries.primaryContainer,
        color: theme.palette2.crm.primaries.onPrimaryContainer,
      },
      '&.tonal': {
        background: theme.palette2.crm.secondaries.secondaryContainer,
        color: theme.palette2.crm.secondaries.onSecondaryContainer,
      },
      '&.outlined': {
        borderColor: theme.palette2.crm.outlines.outline,
        color: theme.palette2.crm.backgrounds.onBackground,
      },
      '&.text': {
        background: 'transparent',
        color: theme.palette2.crm.backgrounds.onBackground,
      },
    },

    '&.vendas': {
      '&.filled': {
        background: theme.palette2.vendas.primaries.primaryContainer,
        color: theme.palette2.vendas.primaries.onPrimaryContainer,
      },
      '&.tonal': {
        background: theme.palette2.vendas.secondaries.secondaryContainer,
        color: theme.palette2.vendas.secondaries.onSecondaryContainer,
      },
      '&.outlined': {
        borderColor: theme.palette2.vendas.outlines.outline,
        color: theme.palette2.vendas.backgrounds.onBackground,
      },
      '&.text': {
        background: 'transparent',
        color: theme.palette2.vendas.backgrounds.onBackground,
      },
    },

    '&.servicos': {
      '&.filled': {
        background: theme.palette2.servicos.primaries.primaryContainer,
        color: theme.palette2.servicos.primaries.onPrimaryContainer,
      },
      '&.tonal': {
        background: theme.palette2.servicos.secondaries.secondaryContainer,
        color: theme.palette2.servicos.secondaries.onSecondaryContainer,
      },
      '&.outlined': {
        borderColor: theme.palette2.servicos.outlines.outline,
        color: theme.palette2.servicos.backgrounds.onBackground,
      },
      '&.text': {
        background: 'transparent',
        color: theme.palette2.servicos.backgrounds.onBackground,
      },
    },
   
    '&.compras': {
      '&.filled': {
        background: theme.palette2.compras.primaries.primaryContainer,
        color: theme.palette2.compras.primaries.onPrimaryContainer,
      },
      '&.tonal': {
        background: theme.palette2.compras.secondaries.secondaryContainer,
        color: theme.palette2.compras.secondaries.onSecondaryContainer,
      },
      '&.outlined': {
        borderColor: theme.palette2.compras.outlines.outline,
        color: theme.palette2.compras.backgrounds.onBackground,
      },
      '&.text': {
        background: 'transparent',
        color: theme.palette2.compras.backgrounds.onBackground,
      },
    },

    '&.financas': {
      '&.filled': {
        background: theme.palette2.financas.primaries.primaryContainer,
        color: theme.palette2.financas.primaries.onPrimaryContainer,
        '&.background': {
          background: theme.palette2.financas.backgrounds.onBackground,
        },
      },
      '&.tonal': {
        background: theme.palette2.financas.secondaries.secondaryContainer,
        color: theme.palette2.financas.secondaries.onSecondaryContainer,
      },
      '&.outlined': {
        borderColor: theme.palette2.financas.outlines.outline,
        color: theme.palette2.financas.backgrounds.onBackground,
      },
      '&.text': {
        background: 'transparent',
        color: theme.palette2.financas.backgrounds.onBackground,
      },
    },
    
    '&.contador': {
      '&.filled': {
        background: theme.palette2.contador.primaries.primaryContainer,
        color: theme.palette2.contador.primaries.onPrimaryContainer,
      },
      '&.tonal': {
        background: theme.palette2.contador.secondaries.secondaryContainer,
        color: theme.palette2.contador.secondaries.onSecondaryContainer,
      },
      '&.outlined': {
        borderColor: theme.palette2.contador.outlines.outline,
        color: theme.palette2.contador.backgrounds.onBackground,
      },
      '&.text': {
        background: 'transparent',
        color: theme.palette2.contador.backgrounds.onBackground,
      },
    },

  },
  text: {
    
  },
  icon: {

  },
}));

export default useStyles;