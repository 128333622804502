import { makeStyles } from '@mui/styles';
import { IThemeCustom } from 'themes';

const useStyles = makeStyles((theme: IThemeCustom) => ({
  root: {
    '& *': {
      fontFamily: 'Poppins',
    },
  },
  rightnav: {
    width: '100%',

  },
  buttonAjuda: {
    marginTop: '48px',
    marginBottom: '32px',
    textAlign: 'right',
  },  
  main: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: theme.palette2.omie.surfaces.surfaceContainer1,
  },
  head: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
  },
  cardInfo: {
    width: '100%',
    borderRadius: '8px',
    border: `1px solid ${theme.palette2.omie.primaries.primary}`,
    background: theme.palette2.opacities.omie.primary.opacity08,
  },
}));

export default useStyles;