/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Menu, Divider, MenuItem, Grid, Typography, Avatar } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { IUserJwt, LocalStorageService, stageOmie } from 'services';
import ItemMenu from './ItemMenu';
import { useNavigate, useLocation } from 'react-router-dom';
import useApp from 'hooks/useApp';
import useAnalytics from 'hooks/useAnalytics';

const MenuUser = (): JSX.Element => {
  const auth = useAuth();
  const [userData, setUserData] = useState<IUserJwt | null>(auth.user);
  const location = useLocation();

  useEffect(() => {
    const userStorage = LocalStorageService.getUser();
    if (
      userStorage !== null &&
      userData !== null &&
      (userStorage.email !== userData.email || userStorage.first_name !== userData.first_name)
    ) {
      setUserData(LocalStorageService.getUser());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <MenuItem>
      <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
        <Grid item xs={'auto'}>
          <Avatar
            alt={(userData as IUserJwt).display_name}
            src={(userData as IUserJwt).avatar}
            sx={{
              border: 'none !important',
            }}
          ></Avatar>
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            gutterBottom
            component="p"
            noWrap
            sx={{ margin: '5px 0 0 0', padding: 0, lineHeight: 1 }}
          >
            <strong>
              {(userData as IUserJwt).first_name} {(userData as IUserJwt).last_name}
            </strong>
          </Typography>
          <span style={{ margin: 0, padding: 0, lineHeight: 1, verticalAlign: 'middle !important' }}>
            <small>{(userData as IUserJwt).email}</small>
          </span>
        </Grid>
      </Grid>
    </MenuItem>
  );
};

interface MenuHeaderProps {
  anchorEl: Element;
  open: boolean;
  handleClose: () => void;
}

const MenuHeader = (props: MenuHeaderProps): JSX.Element => {
  const { anchorEl, open, handleClose } = props;

  const auth = useAuth();
  const navigate = useNavigate();
  const app = useApp();
  const analytics = useAnalytics('header_button', app.module);

  const handleLogout = async (): Promise<void> => {
    await analytics.click('logout');
    navigate('/logoff', { state: { redirectTo: window.location.href } });
  };

  const handleProfile = async (): Promise<void> => {
    handleClose();
    analytics.click('my_profile');
    const url = `${stageOmie}/edit-profile/`;
    window.open(url, '_blank')?.focus();
  };

  const handleMyApps = async (): Promise<void> => {
    handleClose();
    analytics.click('my_apps');
    navigate('/meus-aplicativos');
  };

  const handlePortal = async (): Promise<void> => {
    handleClose();
    analytics.click('portal');
    navigate('/home');
  };

  const handleTest = async (): Promise<void> => {
    handleClose();
    analytics.click('start_trial');
    const url = `${stageOmie}/trial`;
    window.open(url, '_blank')?.focus();
  };

  const handleCredit = async (): Promise<void> => {
    handleClose();
    analytics.click('installment_credit');
    navigate('/credito-parcelado');
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        sx: {
          width: '300px !important',
        },
      }}
    >
      <MenuUser />
      <Divider />

      {app.module === 'portal' && auth.user !== null && auth.user.myApps > 0 && (
        <ItemMenu label={'Meus Aplicativos'} onClick={handleMyApps} icon={'table-cells'} />
      )}
      {app.module === 'portal' && auth.user !== null && auth.user.myApps === 0 && (
        <ItemMenu label={'Faça um teste agora do Omie ERP'} onClick={handleTest} icon={'rocket-launch'} bold />
      )}
      {app.module === 'my-apps' && <ItemMenu label={'Portal Omie'} onClick={handlePortal} icon={'globe'} />}
      <ItemMenu label={'Meu Perfil'} onClick={handleProfile} icon={'user'} />

      {app.module === 'portal' && (
        <>
          <Divider />
          <ItemMenu label={'Crédito Parcelado'} onClick={handleCredit} icon={'dollar'} />
        </>
      )}

      <Divider />
      <ItemMenu label={'Sair'} onClick={handleLogout} icon={'sign-out'} />
    </Menu>
  );
};

export default MenuHeader;
