import { stringToBase64 } from 'utils/converts';
import { api   } from 'services';
import { Logger, LocalStorageService } from 'services';
import { isLocalhost } from 'services';
import { WindowOmie } from 'typings/window';
import { matchPath } from 'react-router-dom';
// import { getStage } from 'utils/stage';
import { AppModule } from 'interfaces/app';
import { getStageOmie } from 'utils/stage';

const base64ToUInt8Array = (b64: string): Uint8Array => Uint8Array.from(window.atob(b64), (c) => c.charCodeAt(0));
const textToUInt8Array = (text: string): Uint8Array => new TextEncoder().encode(text);
// eslint-disable-next-line max-len
const UInt8ArrayToString = (u8: Uint8Array): string =>
  String.fromCharCode.apply(null, Array.from<number>(new Uint8Array(u8)));
const UInt8ArrayToBase64 = (u8: Uint8Array): string => window.btoa(UInt8ArrayToString(u8));

export type EventTrackingAction = string;
export type EventTrackingContext = string | null;
export type EventTrackingDescription = string | number | null;
export type EventTrackingClientOrigin = AppModule;

export interface EventTrackingScreenSize {
  width: number;
  height: number;
}

interface EventTrackingKeysBase {
  [key: string]: unknown;
}

export interface EventTracking extends EventTrackingKeysBase {
  app_hash: string | null;
  document: string | null;
  action: EventTrackingAction;
  context: EventTrackingContext;
  description: EventTrackingDescription;
  current_page: string;
  referer: string | null;
  screen_size: EventTrackingScreenSize;
  client_origin: EventTrackingClientOrigin;
  is_customer: boolean;
}

export interface EventTrackingError extends EventTracking {
  error?: {
    errorMessage: string;
    errorStack: string;
    currentUrl: string;
  };
  lastEvents?: EventTracking[];
}

type EventTrackingKeys = keyof EventTrackingError | keyof EventTracking;

export type EventTrackingOptions = {
  [key in EventTrackingKeys]: unknown;
};

class QueueTracking {
  queue: EventTracking[];

  action: Tracking;

  working: boolean;

  constructor(action: Tracking) {
    this.queue = [];
    this.action = action;
    this.working = false;
  }

  async enqueue(item: EventTracking): Promise<void> {
    this.queue.push(item);
  }

  isEmpty(): boolean {
    return this.queue.length === 0;
  }

  async run(): Promise<void> {
    if (!this.working) {
      if (!this.isEmpty()) {
        this.working = true;
        const item = this.queue.shift();
        if (item) {
          await this.action.execute(item);
          this.working = false;
          return this.run();
        }
      }
    }
  }
}

class Tracking {
  sessionId: string;

  queue: QueueTracking;

  referer: string | null;

  verbose: boolean;

  lastEvents: EventTracking[] = [];

  isCustomer: boolean;

  constructor(sessionId: string) {
    this.sessionId = stringToBase64(sessionId.replace(/[-]/g, '').slice(0, 32));
    this.queue = new QueueTracking(this);
    this.referer = null;
    this.verbose = isLocalhost;
    this.isCustomer = LocalStorageService.getIsCustomer();
    if (isLocalhost) {
      Logger.tracking('Track disabled on development environment');
    } else {
      if (Logger.verbose) Logger.tracking('Iniciando tracking');
    }
  }

  async cripto(event: EventTracking | EventTrackingError): Promise<{ n: string; c: string }> {
    const text = JSON.stringify(event);
    const key = await window.crypto.subtle.importKey(
      'raw',
      base64ToUInt8Array(this.sessionId),
      {
        name: 'AES-CTR',
      },
      false,
      ['encrypt', 'decrypt'],
    );
    const iv = window.crypto.getRandomValues(new Uint8Array(16));

    const ciphertext = new Uint8Array(
      await window.crypto.subtle.encrypt(
        {
          name: 'AES-CTR',
          counter: iv,
          length: 128,
        },
        key,
        textToUInt8Array(text),
      ),
    );
    const encrypted = {
      n: UInt8ArrayToBase64(iv),
      c: UInt8ArrayToBase64(ciphertext),
    };

    return encrypted;
  }

  async execute(eventObject: EventTracking | EventTrackingError): Promise<void> {
    const isError = eventObject.hasOwnProperty('error');

    if (!isError) {
      if (this.lastEvents.length >= 10) {
        this.lastEvents.shift();
      }
      this.lastEvents.push(eventObject);
    }

    if (!isError) {
      if (window.Conpass) {
        window.Conpass.eventCounter([
          {
            ...eventObject,
            property: `${eventObject.action}:${eventObject.context}:${eventObject.description}`,
          },
        ]);
      }
    }

    if (isLocalhost) return;
    // if (getStage() === 'release') {
    //   Logger.info(eventObject);
    //   return;
    // }

    const route = isError ? '/crashalytics' : '/analytics';
    const encrypted = await this.cripto(eventObject);

    await api.post(route, encrypted, { recaptcha: { useRecaptcha: false } });

    // TODO: remover após validação do time do Jean
    if (eventObject.app_hash) {
      const payloadPortal = {
        app_hash: eventObject.app_hash, 
      };
      const url = `${getStageOmie()}/omie-admin/ads/user`;
      try {
        api.post<any>(url, payloadPortal);
      } catch (e) {
      }
    }
  }

  async post(
    action: EventTrackingAction,
    context: EventTrackingContext,
    description: EventTrackingDescription,
    origin: EventTrackingClientOrigin,
    options?: EventTrackingOptions,
    error?: EventTrackingError['error'],
  ): Promise<void> {
    const eventObject: EventTracking | EventTrackingError = {
      client_origin: origin,
      app_hash: null,
      document: null,
      action: action,
      context: context,
      description: description,
      current_page: window.location.pathname,
      referer: this.referer,
      screen_size: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      is_customer: this.isCustomer,
    };

    if (eventObject.current_page.includes('/view/')) {
      eventObject.document = eventObject.current_page.split('/view/')[1];
    }

    const matchProviderPage = matchPath({ path: '/:provider' }, window.location.pathname);
    const matchProviderProductPage = matchPath({ path: '/:provider/:productAlias' }, window.location.pathname);
    const matchMyCart = matchPath({ path: '/meu-carrinho' }, window.location.pathname);
    const matchOrders = matchPath({ path: '/meus-pedidos' }, window.location.pathname);
    const matchHistoric = matchPath({ path: '/historico-financeiro' }, window.location.pathname);
    const matchRestriction = matchPath({ path: '/consulta-cnpj-cpf' }, window.location.pathname);
    if (
      (matchProviderPage || matchProviderProductPage) &&
      !matchMyCart &&
      !matchOrders &&
      !matchHistoric &&
      !matchRestriction
    ) {
      const provider = matchProviderPage
        ? matchProviderPage.params.provider
        : matchProviderProductPage?.params.provider;
      if (provider) {
        const regex = new RegExp(/.+(\-.+)$/);
        const appHash = regex.exec(provider);
        if (appHash) {
          eventObject.app_hash = appHash[1].replace('-', '');
        }
      }
    }

    if (options) {
      if (options.app_hash) {
        eventObject.app_hash = options.app_hash as string;
      }
      if (options.document) {
        eventObject.document = options.document as string;
      }
    }

    if (eventObject.current_page === '/meus-aplicativos' && eventObject.app_hash === 'aplicativos') {
      eventObject.app_hash = null;
    }

    if (error) {
      (eventObject as EventTrackingError).error = error;
      (eventObject as EventTrackingError).lastEvents = this.lastEvents;
    }

    if (this.verbose) {
      Logger.tracking(eventObject);
    }

    await this.queue.enqueue(eventObject);
    this.queue.run();
  }

  async click(
    context: EventTrackingContext,
    description: EventTrackingDescription,
    origin: EventTrackingClientOrigin,
    options?: EventTrackingOptions,
  ): Promise<void> {
    await this.post('click', context, description, origin, options);
  }

  async download(
    context: EventTrackingContext,
    description: EventTrackingDescription,
    origin: EventTrackingClientOrigin,
    options?: EventTrackingOptions,
  ): Promise<void> {
    await this.post('download', context, description, origin, options);
  }

  async share(
    context: EventTrackingContext,
    description: EventTrackingDescription,
    origin: EventTrackingClientOrigin,
    options?: EventTrackingOptions,
  ): Promise<void> {
    await this.post('share', context, description, origin, options);
  }

  async loadPage(
    context: EventTrackingContext,
    description: EventTrackingDescription,
    origin: EventTrackingClientOrigin,
    options?: EventTrackingOptions,
  ): Promise<void> {
    await this.post('load_page', context, description, origin, options);
  }

  async appCrash(errorMessage: string, errorStack: string, origin: EventTrackingClientOrigin): Promise<void> {
    const error: EventTrackingError['error'] = {
      errorMessage: errorMessage,
      errorStack: errorStack,
      currentUrl: window.location.href,
    };
    await this.post('app_crash', null, null, origin, undefined, error);
  }

  async search(
    context: EventTrackingContext,
    description: EventTrackingDescription,
    origin: EventTrackingClientOrigin,
    options?: EventTrackingOptions,
  ): Promise<void> {
    await this.post('search', context, description, origin, options);
  }

  setVerbose(verbose: boolean): boolean {
    this.verbose = verbose;
    if (verbose) {
      Logger.tracking('Verbose mode enabled');
    } else {
      Logger.tracking('Verbose mode disabled');
    }
    return verbose;
  }

  getLastEvents(): EventTracking[] {
    return this.lastEvents;
  }
}

export async function initTracker(): Promise<void> {
  const sessionStorage = LocalStorageService.getSessionToken();
  if (sessionStorage) {
    if (!window.omie) {
      window.omie = {} as WindowOmie;
    }
    window.omie.tracker = new Tracking(sessionStorage);
  } else {
    Logger.tracking('Não foi possível iniciar o tracking por não haver uma session');
  }
}
