import { useContext, useState, useEffect } from 'react';
import { AppContextModule, AppModule } from 'interfaces/app';
import { AppContext } from 'context/AppContext';
import { useLocation } from 'react-router-dom';
import { Menu } from 'interfaces/menu-items';
import itemsMyApps from 'menu-items/my-apps';
import itemsPortal from 'menu-items/portal';
import useAuth from './useAuth';
import { IUserJwt, Logger } from 'services';
import { getRecaptchaToken } from 'utils/stage';
import LoadingSpinner from 'components/LoadingSpinner';
import { recaptchaContainer } from 'store/constant';
import itemsPortalV2 from 'menu-items/portal-v2';

interface IProviderAppContext {
  module: AppModule;
  menu: Menu;
}

function isMyApps(): boolean {
  const location = window.location;
  const myAppsPaths = ['/meus-aplicativos', '/my-apps', '/treinamentos'];
  const myAppsPathsLength: number = JSON.parse(JSON.stringify(myAppsPaths.length));
  for (let i = 0; i < myAppsPathsLength; i++) {
    myAppsPaths.push(myAppsPaths[i] + '/');
  }
  return myAppsPaths.includes(location.pathname);
}

function isPortalV2(): boolean {
  const location = window.location;
  const portalV2Paths = ['historico-financeiro', 'consulta-cnpj-cpf', 'view'];
  return portalV2Paths.includes(location.pathname.split('/')[1]);
}

function getMenu(user: IUserJwt | null, module: AppModule): Menu {
  if (module === 'my-apps') {
    const itemsMyAppsFix = JSON.parse(JSON.stringify(itemsMyApps));
    if (user && ['Contador', 'Enabler', 'Consultor Financeiro', 'Consultoria'].includes(user.partner_type)) {
      itemsMyAppsFix.children?.push({
        title: 'Área do Parceiro',
        type: 'item',
        url: 'https://app.omie.com.br/partner-area/',
        external: true,
        icon: 'handshake-simple',
        breadcrumbs: false,
      });
    }
    return {
      items: [itemsMyAppsFix],
    };
  }

  if (module === 'portal') {
    if (isPortalV2()) {
      return {
        items: [itemsPortalV2],
      };
    } else {
      return {
        items: [itemsPortal],
      };
    }
  }

  return {
    items: [],
  };
}

async function loadRecaptcha(callback: () => Promise<void>, onError: () => Promise<void>): Promise<void> {
  const scriptLoaded = document.getElementById(recaptchaContainer);
  if (scriptLoaded) return;

  Logger.debug('[RECAPTCHA] Carregando recaptcha scripts');

  const script = document.createElement('script');

  const keyRecaptcha = getRecaptchaToken();

  script.type = 'text/javascript';
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${keyRecaptcha}`;
  script.id = recaptchaContainer;
  script.async = true;
  script.onload = callback;
  script.onerror = onError;

  document.body.appendChild(script);
}

const useProviderApp = (): AppContextModule => {
  const auth = useAuth();
  const location = useLocation();

  const [isLoading, setLoading] = useState(true);
  const isMyAppsModule = isMyApps();
  const appContext: IProviderAppContext = {
    module: isMyAppsModule ? 'my-apps' : 'portal',
    menu: getMenu(auth.user, isMyAppsModule ? 'my-apps' : 'portal'),
  };
  const [recaptchaElement, setRecaptchaElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    (async (): Promise<() => void> => {
      // if (!isLoading) setLoading(true);

      let interval: NodeJS.Timeout;

      function removeRecaptcha(): void {
        Logger.debug('[RECAPTCHA] Removendo recaptcha scripts');

        const script = document.getElementById(recaptchaContainer);
        if (script) {
          script.remove();
          Logger.debug(`[RECAPTCHA] ${recaptchaContainer} removido com sucesso`);
        }

        const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
        if (recaptchaElems.length) {
          const recaptchaArray = Array.from(recaptchaElems);
          recaptchaArray.forEach((elem) => {
            elem.remove();
          });
          Logger.debug('[RECAPTCHA] grecaptcha-badge removido com sucesso');
        }

        setRecaptchaElement(null);
      }

      async function checkRecaptcha(): Promise<void> {
        Logger.debug('[RECAPTCHA]', 'checkRecaptcha', appContext.module);
        if (appContext.module === 'portal') {
          const recaptchaLauncher = document.getElementById(recaptchaContainer);
          if (recaptchaLauncher) {
            clearInterval(interval);
            setRecaptchaElement(recaptchaLauncher);
            setLoading(false);
            Logger.debug('[RECAPTCHA] reCaptcha instanciado');
          }
        } else {
          clearInterval(interval);
          setLoading(false);
        }
      }

      async function loadRecaptchaScript(): Promise<void> {
        try {
          if (appContext.module === 'portal') {
            const callbackLoad = async (): Promise<void> => {
              interval = setInterval(checkRecaptcha, 100);
              Logger.debug('[RECAPTCHA] Scripts carregados com sucesso');
            };
            const callbackOnError = async (): Promise<void> => {
              Logger.debug('[RECAPTCHA] Ocorreu um erro ao carregar o script');
              window.location.reload();
            };
            await loadRecaptcha(callbackLoad, callbackOnError);
          } else {
            removeRecaptcha();
            clearInterval(interval);
            setLoading(false);
          }
        } catch (error) {
          Logger.error(error);
          clearInterval(interval);
          setLoading(false);
        }
      }

      if (!recaptchaElement) {
        await loadRecaptchaScript();
        return () => {};
      } else {
        if (appContext.module !== 'portal') {
          removeRecaptcha();
        }
      }

      interval = setInterval(checkRecaptcha, 100);

      return () => {
        clearInterval(interval);
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.module]);

  return {
    isLoading,
    module: appContext.module,
    menu: appContext.menu,
  };
};

export function ProviderApp({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const app = useProviderApp();

  if (app.isLoading) {
    Logger.debug('Aguardando useProviderApp');
    return <LoadingSpinner fullScreen />;
  }

  Logger.info('appContext', app);

  return <AppContext.Provider value={app}>{children}</AppContext.Provider>;
}

const useApp = (): AppContextModule => useContext(AppContext);

export default useApp;
